import styled, { css } from "styled-components";
import theme from "../styled/Theme";
import { H5, Title } from "../styled/Typo";

export const Background = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.image && css`
    background: linear-gradient(0deg, rgba(18, 17, 12, 0.6), rgba(18, 17, 12, 0.6)), url(${props => props.image});
    background-position: center;
    background-size: cover;
  `}
`

export const Wrapper = styled.div`
	height: 100%;
  max-width: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`

export const StyledH5 = styled(H5)`

  @media (min-width: ${theme.breakpoints.sm}) {  margin-bottom: 8px}
  @media (min-width: ${theme.breakpoints.md}) {  margin-bottom: 8px}
  @media (min-width: ${theme.breakpoints.xl}) {  margin-bottom: 16px}
  margin-top: 0;
  text-transform: uppercase;
`
export const StyledTitle = styled(Title)`
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  padding: 0 2rem;
  @media (min-width: ${theme.breakpoints.xl}) {max-width: 1537px}

`
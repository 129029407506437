import React from 'react';
import { H4 } from "../styled/Typo";
import styled from "styled-components";
import { ExternalLink } from "./ExternalLink";
import { useSelector } from "react-redux";
import theme from "../styled/Theme";

const StyledAccount = styled.div`
  @media (min-width: ${theme.breakpoints.sm}) {
    grid-column: 1
  }
  @media (min-width: ${theme.breakpoints.md}) {
    grid-column: 1
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    grid-column: 2;
  }
`


const Account = ({ headline, links }) => {
	const { lang } = useSelector( state => state.language )

	return (
			<StyledAccount>
				<H4 uppercase>{headline[lang]}</H4>
				{links.map( (item, key) => <ExternalLink key={key} link={item.link[lang]}>{item.name[lang]}</ExternalLink> )}
			</StyledAccount>
	);
};

export default Account;
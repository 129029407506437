import React from 'react';
import { H4 } from "../styled/Typo";
import { Button } from "../styled/Button";
import { StyledText } from "./Styles";
import { ExternalLink } from "./ExternalLink";
import { useSelector } from "react-redux";


const Newsletter = ({headline,copy, button}) => {
	const { lang } = useSelector(state => state.language)

	return (
			<div>
				<H4 uppercase>{headline[lang]}</H4>
				<StyledText style={{maxWidth: '450px'}}>{copy[lang]}</StyledText>
				<ExternalLink link={button.link[lang]}><Button>{button.text[lang]}</Button></ExternalLink>
			</div>
	);
};

export default Newsletter;
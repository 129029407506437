import styled, { css } from "styled-components"
import theme from "./Theme";


export const Title = styled.div`
	font-family: ${theme.font.main};
	font-weight: ${theme.font.weight.bold};
	
	@media (min-width: ${theme.breakpoints.sm}) {font-size: 28px}
	@media (min-width: ${theme.breakpoints.md}) {font-size: 48px}
	@media (min-width: ${theme.breakpoints.xl}) {font-size: 80px}

  @media (min-width: ${theme.breakpoints.sm}) {line-height: 36px}
  @media (min-width: ${theme.breakpoints.md}) {line-height: 56px}
  @media (min-width: ${theme.breakpoints.xl}) {line-height: 88px}

  @media (min-width: ${theme.breakpoints.sm}) {letter-spacing: -0.16px}
  @media (min-width: ${theme.breakpoints.md}) {letter-spacing: -0.5px}
  @media (min-width: ${theme.breakpoints.xl}) {letter-spacing: -0.67px}

	color: white;
	${props => props.primary && css`
    color: ${props => props.theme.color.primary};
  `}
`

export const H1 = styled.h1`
  font-family: ${theme.font.main};
  font-weight: ${theme.font.weight.bold};
	
	@media (min-width: ${theme.breakpoints.sm}) {font-size: 32px}
	@media (min-width: ${theme.breakpoints.md}) {font-size: 40px}
	@media (min-width: ${theme.breakpoints.xl}) {font-size: 60px}

  @media (min-width: ${theme.breakpoints.sm}) {line-height: 36px}
  @media (min-width: ${theme.breakpoints.md}) {line-height: 48px}
  @media (min-width: ${theme.breakpoints.xl}) {line-height: 66px}

  @media (min-width: ${theme.breakpoints.sm}) {letter-spacing: -0.2px}
  @media (min-width: ${theme.breakpoints.md}) {letter-spacing: -0.3px}
  @media (min-width: ${theme.breakpoints.xl}) {letter-spacing: -0.5px}

  color: white;

  ${props => props.uppercase && css`
    text-transform: uppercase;
  `}

  ${props => props.primary && css`
    color: ${props => props.theme.color.primary};
  `}
`

export const H2 = styled.h2`
	font-family: ${theme.font.main};
  font-weight: ${theme.font.weight.bold};
	
	@media (min-width: ${theme.breakpoints.sm}) {font-size: 26px}
	@media (min-width: ${theme.breakpoints.md}) {font-size: 32px}
	@media (min-width: ${theme.breakpoints.xl}) {font-size: 48px}

  @media (min-width: ${theme.breakpoints.sm}) {line-height: 30px}
  @media (min-width: ${theme.breakpoints.md}) {line-height: 36px}
  @media (min-width: ${theme.breakpoints.xl}) {line-height: 60px}

  @media (min-width: ${theme.breakpoints.sm}) {letter-spacing: -0.2px}
  @media (min-width: ${theme.breakpoints.md}) {letter-spacing: -0.3px}
  @media (min-width: ${theme.breakpoints.xl}) {letter-spacing: -0.5px}

  color: white;

  ${props => props.uppercase && css`
    text-transform: uppercase;
  `}
  
  ${props => props.primary && css`
    color: ${props => props.theme.color.primary};
  `}
`

export const H3 = styled.h3`
	font-family: ${theme.font.main};
  font-weight: ${theme.font.weight.bold};
	
	@media (min-width: ${theme.breakpoints.sm}) {font-size: 22px}
	@media (min-width: ${theme.breakpoints.md}) {font-size: 24px}
	@media (min-width: ${theme.breakpoints.xl}) {font-size: 32px}

  @media (min-width: ${theme.breakpoints.sm}) {line-height: 30px}
  @media (min-width: ${theme.breakpoints.md}) {line-height: 32px}
  @media (min-width: ${theme.breakpoints.xl}) {line-height: 40px}

  @media (min-width: ${theme.breakpoints.sm}) {letter-spacing: -0.2px}
  @media (min-width: ${theme.breakpoints.md}) {letter-spacing: -0.3px}
  @media (min-width: ${theme.breakpoints.xl}) {letter-spacing: -0.5px}

  color: white;

  ${props => props.uppercase && css`
    text-transform: uppercase;
  `}
  
  ${props => props.primary && css`
    color: ${props => props.theme.color.primary};
  `}
`

export const H4 = styled.h4`
  font-family: ${theme.font.main};
  font-weight: ${theme.font.weight.bold};
	
	@media (min-width: ${theme.breakpoints.sm}) {font-size: 18px}
	@media (min-width: ${theme.breakpoints.md}) {font-size: 20px}
	@media (min-width: ${theme.breakpoints.xl}) {font-size: 24px}

  @media (min-width: ${theme.breakpoints.sm}) {line-height: 26px}
  @media (min-width: ${theme.breakpoints.md}) {line-height: 28px}
  @media (min-width: ${theme.breakpoints.xl}) {line-height: 32px}

  @media (min-width: ${theme.breakpoints.sm}) {letter-spacing: 0px}
  @media (min-width: ${theme.breakpoints.md}) {letter-spacing: 0px}
  @media (min-width: ${theme.breakpoints.xl}) {letter-spacing: 0px}
 
  color: white;

  ${props => props.uppercase && css`
    text-transform: uppercase;
  `}
  
  ${props => props.primary && css`
    color: ${props => props.theme.color.primary};
  `}
`

export const H5 = styled.h5`
	font-family: ${theme.font.main};
  font-weight: ${theme.font.weight.regular};
	
	@media (min-width: ${theme.breakpoints.sm}) {font-size: 16px}
	@media (min-width: ${theme.breakpoints.md}) {font-size: 16px}
	@media (min-width: ${theme.breakpoints.xl}) {font-size: 16px}

  @media (min-width: ${theme.breakpoints.sm}) {line-height: 24px}
  @media (min-width: ${theme.breakpoints.md}) {line-height: 24px}
  @media (min-width: ${theme.breakpoints.xl}) {line-height: 24px}

  @media (min-width: ${theme.breakpoints.sm}) {letter-spacing: 0px}
  @media (min-width: ${theme.breakpoints.md}) {letter-spacing: 0px}
  @media (min-width: ${theme.breakpoints.xl}) {letter-spacing: 0px}

  color: white;
  
  ${props => props.uppercase && css`
    text-transform: uppercase;
  `}
  
  ${props => props.primary && css`
    color: ${props => props.theme.color.primary};
  `}
`

export const Text = styled.div`
	font-family: ${theme.font.main};
  font-weight: ${theme.font.weight.regular};
	
	@media (min-width: ${theme.breakpoints.sm}) {font-size: 14px}
	@media (min-width: ${theme.breakpoints.md}) {font-size: 16px}
	@media (min-width: ${theme.breakpoints.xl}) {font-size: 16px}

  @media (min-width: ${theme.breakpoints.sm}) {line-height: 22px}
  @media (min-width: ${theme.breakpoints.md}) {line-height: 26px}
  @media (min-width: ${theme.breakpoints.xl}) {line-height: 26px}

  @media (min-width: ${theme.breakpoints.sm}) {letter-spacing: 0px}
  @media (min-width: ${theme.breakpoints.md}) {letter-spacing: 0px}
  @media (min-width: ${theme.breakpoints.xl}) {letter-spacing: 0px}

  color: white;

  ${props => props.primary && css`
    color: ${props => props.theme.color.primary};
  `}

  ${props => props.large && css`
		@media (min-width: ${theme.breakpoints.sm}) {font-size: 16px}
		@media (min-width: ${theme.breakpoints.md}) {font-size: 20px}
		@media (min-width: ${theme.breakpoints.xl}) {font-size: 24px}
	
		@media (min-width: ${theme.breakpoints.sm}) {line-height: 24px}
		@media (min-width: ${theme.breakpoints.md}) {line-height: 30px}
		@media (min-width: ${theme.breakpoints.xl}) {line-height: 40px}
	
		@media (min-width: ${theme.breakpoints.sm}) {letter-spacing: 0px}
		@media (min-width: ${theme.breakpoints.md}) {letter-spacing: 0px}
		@media (min-width: ${theme.breakpoints.xl}) {letter-spacing: 0px}
  `}
`




import styled from "styled-components"
import theme from "./Theme";

export const Container = styled.div`
	width: 100%;
  @media (min-width: ${theme.breakpoints.md}) {max-width: 80vw}
  @media (min-width: ${theme.breakpoints.xl}) {max-width: calc(1181px + 1rem)}
	margin: 0 auto;
	padding: 0 1rem;
`

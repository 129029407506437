import styled from "styled-components";
import theme from '../styled/Theme'
import { Text } from "../styled/Typo";

export const StyledFooter = styled.footer`
  @media (min-width: ${theme.breakpoints.sm}) {  margin-top: 56px}
  @media (min-width: ${theme.breakpoints.md}) {  margin-top: 80px}
  @media (min-width: ${theme.breakpoints.xl}) {  margin-top: 84px}
	
  @media (min-width: ${theme.breakpoints.sm}) {  margin-bottom: 26px}
  @media (min-width: ${theme.breakpoints.md}) {  margin-bottom: 33px}
  @media (min-width: ${theme.breakpoints.xl}) {  margin-bottom: 52px}
`


export const FooterGrid = styled.div`
	max-width: calc(1562px + 1rem);
	margin: auto;
  display: grid;
  column-gap: 1rem;

  @media (min-width: ${theme.breakpoints.sm}) {row-gap: 32px}
  @media (min-width: ${theme.breakpoints.md}) {row-gap: 25px}
  @media (min-width: ${theme.breakpoints.xl}) {row-gap: 0px}
	
  @media (min-width: ${theme.breakpoints.sm}) {grid-template-columns: 1fr}
  @media (min-width: ${theme.breakpoints.md}) {grid-template-columns: 1fr 1fr}
  @media (min-width: ${theme.breakpoints.xl}) {grid-template-columns: 1fr 1fr 1fr}
	
  @media (min-width: ${theme.breakpoints.sm}) {padding: 0 32px}
  @media (min-width: ${theme.breakpoints.md}) {padding: 0 32px}
  @media (min-width: ${theme.breakpoints.xl}) {padding: 0 1rem}
`

export const Line = styled.div`
	width: 100%;
  border: solid 1px #979797;
	grid-column: 1/-1;
	margin-bottom: 25px;
`

export const StyledText = styled(Text)`
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	a {
		color: white;
		text-decoration: none;
		&:hover {
			color: ${theme.color.primary};
		}
	}
`
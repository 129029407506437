import React from 'react';
import { Background, StyledH2, StyledH5, Wrapper } from "./Styles";
import { useSelector } from "react-redux";


const ImageSection = ({ overline, headline, image }) => {
	const { lang } = useSelector(state => state.language)

	return (
			<Background image={image}>
				<Wrapper>
					<div>
						<StyledH5 primary>{overline[lang]}</StyledH5>
						<StyledH2>{headline[lang]}</StyledH2>
					</div>
				</Wrapper>
			</Background>
	);
};

export default ImageSection;
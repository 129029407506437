import React, { useState } from 'react';
import styled from "styled-components";

import ContactIcon from '../../images/icon_sticky.svg'
import ContactClose from '../../images/icon_close.svg'
import SVG from "react-inlinesvg";
import theme from "../styled/Theme";
import { H4, Text } from "../styled/Typo";
import { useSelector } from "react-redux";
import { Button } from "../styled/Button";

const StickyWrap = styled.div`
	
  position: fixed;
	z-index: 999;

  display: flex;
  @media (min-width: ${theme.breakpoints.sm}) {
		left: 0;
		right: 0;
		bottom: 40px;
	}
	
  @media (min-width: ${theme.breakpoints.md}) {
    right: 0;
		left: auto;
		bottom: auto;
    top: 30vh;
	}
`
const StickyName = styled(Button)`
	width: 100%;
	text-align: center;
  @media (min-width: ${theme.breakpoints.md}) {display: none}
`

const StickyFlag = styled.div`
  @media (max-width: ${theme.breakpoints.md}) {display: none}

  cursor: pointer;
  @media (min-width: ${theme.breakpoints.sm}) {width: 100%}
  @media (min-width: ${theme.breakpoints.md}) {width: 31px}
  @media (min-width: ${theme.breakpoints.md}) {width: 40px}
	
  @media (min-width: ${theme.breakpoints.sm}) {height: 31px}
  @media (min-width: ${theme.breakpoints.md}) {height: 31px}
  @media (min-width: ${theme.breakpoints.md}) {height: 40px}
	
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${theme.color.primary};
	
`
const StickyBody = styled.div`
	* {color: ${theme.color.black}}
	max-width: 280px;
	padding: 32px;
	background: ${theme.color.primary};
`

const label = {
	de: 'Kontakt',
	en: 'Contact',
	it: 'Contatto',
	fr: 'Contact',
}

const ContactSticky = ({headline, copy, link}) => {
	const { lang } = useSelector( state => state.language )
	const { contactInView } = useSelector( state => state )

	console.log(contactInView)

	const [isOpen, setIsOpen] = useState(false)
	return (
			<>
				{!contactInView && (
						<StickyWrap>
							<StickyName as={'a'} href={'#el-6'}>{label[lang]}</StickyName>

							<StickyFlag onClick={()=> setIsOpen(!isOpen)}>
								{isOpen ? (
										<SVG src={ContactClose}/>
								) : (
										<SVG src={ContactIcon}/>
								)}
							</StickyFlag>
							{isOpen && (
									<StickyBody>
										<H4 uppercase>{headline[lang]}</H4>
										<Text>{copy[lang]}</Text>
										<a href="#el-6" onClick={() => setIsOpen(false)}>{link[lang]}</a>
									</StickyBody>
							) }
						</StickyWrap>
				)}
			</>

	);
};

export default ContactSticky;
import React from 'react';
import Logo from "../../images/logo_rodcraft.svg";
import { Text } from "../styled/Typo";
import { FooterGrid, Line } from "./Styles";
import styled from "styled-components";
import theme from "../styled/Theme";
import { useSelector } from "react-redux";


const StyledFooterGrid = styled(FooterGrid)`

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: 32px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: 40px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-top: 108px
  }
	
	img {
    @media (min-width: ${theme.breakpoints.sm}) {width: 143px}
    @media (min-width: ${theme.breakpoints.md}) {width: 190px}
    @media (min-width: ${theme.breakpoints.xl}) {width: 286px}
    height: auto;
	}
`

const subline = {
	de: 'Alle Rechte vorbehalten',
	en: 'All rights reserved',
	it: 'Tutti i diritti riservati',
	fr: 'Tous droits réservés',

}

const BottomLine = () => {
	const { lang } = useSelector(state => state.language)

	return (
			<StyledFooterGrid>
				<Line />
				<img src={Logo} alt="Rodcraft Logo"/>
				<Text>Rodcraft. {new Date().getFullYear()}. {subline[lang]}</Text>
			</StyledFooterGrid>
	)
}

export default BottomLine;
import * as React from "react"
import Footer from "../components/Footer/Footer";
import ContentSwitcher from "../components/ContentSwitcher/ContentSwitcher";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from "styled-components";
import theme from "../components/styled/Theme";

import './css/global.css'
import Navigation from "../components/Navigation/Navigation";
import { store } from "../store/store";
import { Provider, useSelector } from "react-redux";
import { content } from "../data/content";
import { navigation } from "../data/navigation";
import { footer } from "../data/footer";
import Register from "../components/Product/Register/Register";
import { Helmet } from "react-helmet";
import ContactSticky from "../components/ContactSticky/ContactSticky";
import { useEffect } from "react";

const data = {
	title: {
		de: 'Druckluft-Bohrmaschinen und -Drehschrauber',
		en: 'Pneumatic drills and screwdrivers',
		it: 'Trapani e avvitatori ad aria compressa',
		fr: 'Perceuses et visseuses Rodcraft',
	},
	sticky: {
		headline: {
			de: 'Interessiert?',
			en: 'INTERESTED?',
			fr: 'INTÉRESSÉ(E) ?',
			it: 'INTERESSATO? ',
		},
		copy: {
			de: 'Schreiben Sie eine Nachricht!',
			en: 'CONTACT US!',
			fr: '',
			it: 'CONTATTACI!',
		},
		link: {
			de: 'Zum Kontakt',
			en: 'TO CONTACT',
			it: 'Al contatto',
			fr: 'CONTACTEZ-NOUS !',
		},
	},
	navigation,
	content,
	footer
}

const Head = ({title}) => {
	const { lang } = useSelector(state => state.language)
	return (<Helmet title={title[lang]} defer={false} />)
}

// markup
const IndexPage = ({pageContext}) => {

	useEffect(() => {
		store.dispatch({type: 'setLanguage', lang: pageContext.activeLanguage || 'de'})
	}, [])
	console.log(pageContext)
	// console.log(data
	return (
			<>

				<ThemeProvider theme={theme}>
					<Provider store={store}>
						<Head title={data.title}/>
						<Navigation {...data.navigation}/>
						<ContactSticky {...data.sticky}/>
						<main>
							<ContentSwitcher content={data.content}/>
						</main>

						<Register />
						<Footer {...data.footer}/>
					</Provider>
				</ThemeProvider>
			</>
	)
}

export default IndexPage

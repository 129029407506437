import React from 'react';
import { Background, StyledH5, StyledTitle, Wrapper } from "./Styles";
import { useSelector } from "react-redux";


const Header = ({id, overline, headline, image}) => {
	const { lang } = useSelector(state => state.language)

	return (
			<Background image={image} id={`el-${id}`}>
				<Wrapper>
					<div>
						<StyledH5 primary>{overline[lang]}</StyledH5>
						<StyledTitle>{headline[lang]}</StyledTitle>
					</div>
				</Wrapper>
			</Background>
	);
};

export default Header;
export const footer = {
	contact: {
		headline: {
			de: 'KONTAKTINFORMATION',
			en: 'CONTACT INFORMATION',
			it: 'CONTACT INFORMATION',
			fr: 'INFORMATION DE CONTACT\n',
		},
		copy: {
			de: 'RODCRAFT Central Europe <br/>\n' +
					'Edmund-Seng-Str. 3-5 <br/>\n' +
					'63477 DE-Maintal<br/>\n' +
					'Telefon:<br/>\n' +
					'<a href="tel: +49 (0)6181 411-130">+49 (0)6181 411-130</a>',
			en: 'SALES OFFICE<br/>\n' +
					'38, rue Bobby Sands<br/>\n' +
					'ZAC de la Lorie<br/>\n' +
					'44800 Saint Herblain - France<br/>\n' +
					'Phone:<br/>\n' +
					'<a href="tel: +33 (0)2 40 80 20 00">+33 (0)2 40 80 20 00</a>',
			it: 'RODCRAFT ITALIA<br> Telefon:<br><a href="tel: +39 334.641.69.01">+39 334.641.69.01</a>',
			fr: '38 rue Bobby Sands,<br>' +
					'ZAC de la Lorie,<br>' +
					'44800 Saint-Herblain, France<br> Téléphone:<br><a href="tel: +33 (0) 240 802 000">+33 (0) 240 802 000</a>',

		},
		button: {
			text: {
				de: 'Kontaktanfrage',
				en: 'Contact us',
				it: 'RICHIESTA DI CONTATTO',
				fr: 'CONTACTEZ-NOUS',
			},
			link: {
				de: '#el-6',
				en: '#el-6',
				it: '#el-6',
				fr: '#el-6',
			}
		},
	},
	newsletter: {
		headline: {
			de: 'RODCRAFT NEWSLETTER',
			en: 'STAY CONNECTED WITH US:',
			it: 'RIMANETE INFORMATI:',
			fr: 'RESTEZ CONNECTES AVEC RODCRAFT:',
		},
		copy: {
			de: 'Abonnieren Sie unseren Newsletter mit aktuellsten Informationen über Produkte, Aktionen und Neuheiten. Melden Sie sich mit Ihrer E-Mail Adresse für unseren Newsletter an.',
			en: 'Subscribe to our newsletter: get all the latest information on products, sales and news. Subscribe to our newsletter today by entering your email address',
			it: 'Iscriviti alla nostra newsletter: ottieni tutte le ultime informazioni su prodotti, vendite e novità. Iscriviti oggi alla nostra newsletter inserendo il tuo indirizzo email',
			fr: 'Inscrivez-vous à notre newsletter en entrant votre adresse e-mail pour recevoir nos dernières informations sur nos produits et promotions'
		},
		button: {
			text: {
				de: 'Jetzt abonnieren',
				en: 'Subscribe',
				it: 'SOTTOSCRIVERE',
				fr: 'INSCRIPTION',
			},
			link: {
				de: 'https://www.rodcraft.com/de/newsletter-abonnement',
				en: 'https://www.rodcraft.com/de/newsletter-abonnement',
				it: 'https://www.rodcraft.com/de/newsletter-abonnement',
				fr: 'https://www.rodcraft.com/de/newsletter-abonnement',
			}
		},
	},
	account: {
		headline: {
			de: 'Konto und Einstellungen',
			en: 'My Account',
			it: 'ACCOUNT E IMPOSTAZIONI',
			fr: 'MON COMPTE',
		},
		links: [
			{
				name: {
					de: 'Mein Account',
					en: 'About Rodcraft',
					it: 'Il mio account',
					fr: 'A propos de Rodcraft',
				},
				link: {
					de: 'https://www.rodcraft.com/de/customer/account/',
					en: 'https://www.rodcraft.com/gb/about_rodcraft/',
					it: 'https://www.rodcraft.com/it/customer/account/',
					fr: 'https://www.rodcraft.com/fr/a-propos-rodcraft/',
				}
			},
			{
				name: {
					de: 'Login',
					en: 'Customer Centers',
					it: 'Accesso',
					fr: 'Centres clients',
				},
				link: {
					de: 'https://www.rodcraft.com/de/customer/account/login/',
					en: 'https://www.rodcraft.com/gb/global-contact/',
					it: 'https://www.rodcraft.com/it/customer/account/login/',
					fr: 'https://www.rodcraft.com/fr/global-contact/',
				}
			},
			{
				name: {
					de: 'Fragen und Antworten',
					en: 'Legal Notice, Privacy Policys',
					it: 'domande e risposte',
					fr: 'Mentions légales',
				},
				link: {
					de: 'https://www.rodcraft.com/de/faq/',
					en: 'https://www.rodcraft.com/gb/uk-legal/',
					it: 'https://www.rodcraft.com/it/faq/',
					fr: 'https://www.rodcraft.com/fr/fr-mentions-legales/',
				}
			},
			{
				name: {
					de: 'Lieferungen',
					en: 'Advanced Search',
					it: 'Contattaci',
					fr: 'Recherche avancée',
				},
				link: {
					de: 'https://www.rodcraft.com/de/lieferungen/',
					en: 'https://www.rodcraft.com/gb/catalogsearch/advanced/',
					it: 'https://www.rodcraft.com/it/support-desk/',
					fr: 'https://www.rodcraft.com/fr/catalogsearch/advanced/',
				}
			},
			{
				name: {
					de: 'Zahlungsweisen',
					en: '',
					it: 'Catalogo',
				},
				link: {
					de: 'https://www.rodcraft.com/de/zahlungsweisen/',
					en: '',
					it: 'https://www.rodcraft.com/it/catalogues-pdf-it/',
				}
			},
			{
				name: {
					de: 'Seitenverzeichnis',
					en: 'Site map',
					it: 'Mappa del sito',
					fr: 'Plan du site',
				},
				link: {
					de: 'https://www.rodcraft.com/de/sitemap/',
					en: 'https://www.rodcraft.com/gb/sitemap/',
					it: 'https://www.rodcraft.com/it/sitemap/',
					fr: 'https://www.rodcraft.com/fr/sitemap/',
				}
			},
		]
	},
	terms: {
		headline: {
			de: 'Benutzerbedingungen',
			en: '',
			it: 'NOTE LEGALI',
		},
		links: [
			{
				name: {
					de: 'AGBs',
					en: '',
					it: 'Condizioni generali di vendita',
				},
				link: {
					de: 'https://www.rodcraft.com/de/customer/account/',
					en: '',
					it: 'Condizioni generali di vendita',
				}
			},
			{
				name: {
					de: 'Nutzungsbedingungen der Website',
					en: '',
					it: 'Condizioni di utilizzo del sito web',
				},
				link: {
					de: 'https://www.rodcraft.com/de/nutzungsbedingungen-der-website/',
					en: '',
					it: 'https://www.rodcraft.com/it/Condizioni-di-utilizzo-del-sito-web/',
				}
			},
			{
				name: {
					de: 'Impressum',
					en: '',
					it: 'Protezione dati',
				},
				link: {
					de: 'https://www.rodcraft.com/de/Impressum/',
					en: '',
					it: 'https://www.rodcraft.com/it/Protezione-dati/',
				}
			},
			{
				name: {
					de: 'Datenschutzerklärung',
					en: ''
				},
				link: {
					de: 'https://www.rodcraft.com/de/datenschutzerklarung/',
					en: ''
				}
			},
		]
	},
	social: {
		headline: {
			de: 'Social Media',
			en: 'Social Media',
			it: 'Social Media',
			fr: 'RESEAUX SOCIAUX',
		},
		links: {
			linkedIn: 'https://www.linkedin.com/company/rodcraft/',
			facebook: 'https://www.facebook.com/Rodcraft.de/',
			instagram: 'https://instagram.com/rodcraft_central_europe?igshid=101l84ju0q1fc'
		},
		headline2: {
			de: 'Sichere Zahlungen',
			en: 'Secure Payment',
			it: 'PAGAMENTO SICURO AL 100%',
			fr: 'Paiements sécurisés',
		},
		link: {
			de: 'https://www.rodcraft.com/de/zahlungsweisen/',
			en: '',
			it: 'https://www.rodcraft.com/it/pagamenti/'
		}
	}
}
import { combineReducers, createStore } from 'redux'


function LanguageReducer(state = { lang: 'de' }, action) {
	switch ( action.type ) {
		case 'setLanguage':
			return { lang: action.lang }
		default:
			return state
	}
}

function ActiveTabsReducer(state = [
	{ elementId: 4, product: 'RC4108' },
	{ elementId: 41, product: 'RC4710' },
], action) {
	switch ( action.type ) {
		case 'setActiveTab':
			return [
				...state.filter( el => el.elementId !== action.elementId ),
				{ elementId: action.elementId, product: action.product }
			]
		default:
			return state
	}
}

function ContactInViewReducer(state = false, action) {
	switch ( action.type ) {
		case 'contact_view':
			return action.inView
		default:
			return state
	}
}

export let store = createStore( combineReducers( {
	language: LanguageReducer,
	activeTabs: ActiveTabsReducer,
	contactInView: ContactInViewReducer
} ) )



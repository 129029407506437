import React, { useState } from 'react';
import { H3 } from "../../styled/Typo";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Button } from "../../styled/Button";
import {
	LargeImage,
	PdfLink,
	Point,
	PointWrapper,
	PopoverH4,
	PopoverText,
	RegisterButton,
	RegisterButtons,
	RegisterContent,
	RegisterHeader,
	RegisterLinks,
	RegisterWrapper
} from "./Styles";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import Download from '../../../images/Download.svg'
import styled from "styled-components";


const StyledPopOver = styled( Popover )`
  &[x-placement="right"] {
    margin-left: -30px;
  }

  &[x-placement="left"] {
    margin-right: -30px;
  }

  &[x-placement="top"] {
    margin-bottom: -30px;
  }

  &[x-placement="bottom"] {
    margin-top: -30px;

  }
`


const Points = ({ register }) => {

	const [ openPoint, setOpenPoint ] = useState()
	const { lang } = useSelector( state => state.language )


	return (
			<PointWrapper>
				{register.points.map( (point, i) => (
						<>{
							(point.headline[lang]) ? (
									<OverlayTrigger
											key={i}
											placement="auto"
											show={openPoint === i}
											overlay={
												<StyledPopOver>
													<div></div>
													<Popover.Header as="div"><PopoverH4>{point.headline[lang]}</PopoverH4></Popover.Header>
													<Popover.Body>
														<PopoverText
																large
																dangerouslySetInnerHTML={{ __html: point.copy[lang] }}/>
													</Popover.Body>
												</StyledPopOver>
											}
									>
										<Point
												active={openPoint === i}
												as={'div'}
												x={point.x}
												y={point.y}
												onClick={() => openPoint === i ? setOpenPoint( null ) : setOpenPoint( i )}>{i + 1}</Point>

									</OverlayTrigger>
							) : null}

						</>
				) )}
			</PointWrapper>
	)
}

const datenblatt = {
	de: 'Datenblatt Download',
	en: 'Data sheet',
	it: 'Scheda tecnica',
	fr: 'Fiche technique',
}

const toShop = {
	de: 'Zum Produkt',
	en: 'To Product',
	it: 'il prodotto',
	fr: 'Voir le produit',

}

const Register = ({ productId, overline, headline, registers, image, url, pdf }) => {
	const [ openRegister, setOpenRegister ] = useState( 0 )
	const activeTabs = useSelector( state => state.activeTabs )
	const { lang } = useSelector( state => state.language )

	if ( activeTabs.some( tab => tab.product === productId ) ) {
		return (
				<div>
					<RegisterHeader>
						<H3 primary uppercase>{overline[lang]}</H3>
						<H3 uppercase>{headline[lang]}</H3>
					</RegisterHeader>

					<RegisterWrapper>
						<RegisterButtons>
							{registers && registers.map( (register, i) => (
									<RegisterButton
											active={openRegister === i}
											key={i}
											onClick={() => setOpenRegister( i )}>{register.name[lang]}</RegisterButton>) )}
						</RegisterButtons>

						<RegisterContent>
							<div style={{ position: 'relative' }}>
								<LargeImage src={image} alt=""/>
								{registers && registers.map( (register, i) => openRegister === i && (
										<Points key={i} register={register}/>
								) )}
							</div>
						</RegisterContent>

						<RegisterLinks>
							<div className={'d-flex flex-column align-items-center'}>
								<PdfLink target="_blank" href={pdf[lang]}><SVG src={Download}/>{datenblatt[lang]}</PdfLink>
								<Button target="_blank" as={'a'} href={url[lang]}>{toShop[lang]}</Button>
							</div>
						</RegisterLinks>

					</RegisterWrapper>
				</div>
		);
	}
	return null
};

export default Register;
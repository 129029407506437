import styled, { css } from "styled-components";
import theme from "../styled/Theme";
import { H2, H5 } from "../styled/Typo";

export const Background = styled.div`
  @media (min-width: ${theme.breakpoints.sm}) {min-height: 583px}
  @media (min-width: ${theme.breakpoints.md}) {min-height: 468px}
  @media (min-width: ${theme.breakpoints.xl}) {min-height: 578px}
  display: flex;
  align-items: center;
  justify-content: center;
  
  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }
  @supports not (-webkit-touch-callout: none) {
    background-attachment: fixed;
  }
  ${props => props.image && css`
    background: linear-gradient(0deg, rgba(18, 17, 12, 0.6), rgba(18, 17, 12, 0.6)), url(${props => props.image});
    background-position: center;
    background-size: cover;
  `}
`

export const Wrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`

export const StyledH5 = styled(H5)`
  margin-bottom: 3px;
  margin-top: 0;
  text-transform: uppercase;
`
export const StyledH2 = styled(H2)`
  margin: 0;
  text-transform: uppercase;
`
import React from 'react';
import { H4 } from "../styled/Typo";
import { ExternalLink } from "./ExternalLink";
import { useSelector } from "react-redux";

const Terms = ({headline, links}) => {
	const { lang } = useSelector(state => state.language)

	return (
			<div>
				<H4 uppercase>{headline[lang]}</H4>
				{links.map((item, key) => <ExternalLink key={key} link={item.link[lang]}>{item.name[lang]}</ExternalLink>)}

			</div>
	);
};

export default Terms;
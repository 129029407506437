import React, { useEffect, useState } from 'react';
import { Text } from "../styled/Typo";
import { Container } from "../styled/Container";
import { Background, ReadMore, StyledH2, StyledH5 } from "./Styles";
import theme from "../styled/Theme";
import { useSelector } from "react-redux";


const IntroText = ({ headline, overline, copy, buttonText }) => {
	const [isOpen, setIsOpen] = useState(true)
	const { lang } = useSelector(state => state.language)

	let text = isOpen ? copy[lang] : copy[lang].substring(0, 268) + '...'

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth >= parseInt(theme.breakpoints.xl)) {
				setIsOpen(true)
			} else {
				setIsOpen(false)
			}
		}
		window.addEventListener('resize', handleResize)
	})

	return (
			<Background>
				<Container>
					<StyledH5 primary>{overline[lang]}</StyledH5>
					<StyledH2>{headline[lang]}</StyledH2>
					<Text large dangerouslySetInnerHTML={{ __html: text }}/>
					{!isOpen ? <ReadMore large onClick={() => setIsOpen(!isOpen)}>{buttonText[lang]}</ReadMore> : null}
				</Container>
			</Background>
	);
};

export default IntroText;
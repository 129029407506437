import React from 'react';
import { H4 } from "../styled/Typo";
import { Button } from "../styled/Button";
import styled from "styled-components";
import theme from "../styled/Theme";
import { StyledText } from "./Styles";
import { useSelector } from "react-redux";

const StyledContact = styled.div`
	
	@media (min-width: ${theme.breakpoints.sm}) {grid-column: 1 / -1}
	@media (min-width: ${theme.breakpoints.md}) {grid-column: 1 / -1}
	@media (min-width: ${theme.breakpoints.xl}) {grid-column: 1}
`


const Contact = ({headline, copy, button}) => {
	const { lang } = useSelector(state => state.language)

	return (
			<StyledContact>
				<H4 uppercase>{headline[lang]}</H4>
				<StyledText dangerouslySetInnerHTML={{__html: copy[lang]}}/>
				<Button as={'a'} href={'https://www.rodcraft.com/de/kontakt'} target={'_blank'}>{button.text[lang]}</Button>
			</StyledContact>
	);
};

export default Contact;
import React from 'react';
import { H4 } from "../styled/Typo";

import Paypal from '../../images/Icon Paypal.svg'
import Visa from '../../images/Icon Visa.svg'
import MasterCard from '../../images/Icon Mastercard.svg'
import LinkedIn from '../../images/Icon LinkedIn.svg'
import Facebook from '../../images/Icon Facebook.svg'
import Instagram from '../../images/Icon Instagram.svg'


import styled, { css } from "styled-components";
import { ExternalLink } from "./ExternalLink";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";

const IconWrap = styled.div`
	margin-top: 1rem;
	margin-bottom: 2rem;
	display: flex;
	

	a + a, img + img {
		margin-left: 1rem;
	}
	${props => props.large && css`
    a + a {
      margin-left: 2rem;
    }
	`}
	
`

const SocialPayment = ({headline, links, headline2, link}) => {
	const { lang } = useSelector(state => state.language)

	return (
			<div>
				<H4 as={'div'} uppercase>{headline[lang]}</H4>
				<IconWrap large>
					<ExternalLink link={links.linkedIn}>
						<SVG src={LinkedIn} alt=""/>
					</ExternalLink>
					<ExternalLink link={links.facebook}>
						<SVG src={Facebook} alt=""/>
					</ExternalLink>
					<ExternalLink link={links.instagram}>
						<SVG src={Instagram} alt=""/>
					</ExternalLink>
				</IconWrap>
				<ExternalLink link={link[lang]}>
				<H4 as={'div'} uppercase>{headline2[lang]}</H4>
				<IconWrap>
						<img src={Visa} alt=""/>
						<img src={MasterCard} alt=""/>
						<img src={Paypal} alt=""/>
				</IconWrap>
				</ExternalLink>
			</div>
	);
};

export default SocialPayment;
export const navigation = {
	items: [
		{
			target: '#el-2',
			title: {
				de: 'Einführung',
				en: 'Introduction',
				it: 'Introduzione',
				fr: 'Présentation',
			}
		},
		{
			target: '#el-3',
			title: {
				de: 'Bohrmaschinen',
				en: 'Drills',
				it: 'Trapani',
				fr: 'Perceuses',
			}
		},
		{
			target: '#el-31',
			title: {
				de: 'Drehschrauber',
				en: 'Screwdrivers',
				it: 'Cacciaviti',
				fr: 'Tournevis',
			}
		},
		{
			target: '#el-6',
			title: {
				de: 'Kontakt',
				en: 'Contact',
				it: 'Contatto',
				fr: 'Contact',
			}
		},
	],
	languages: [
		{ name: 'Deutsch', id: 'de' },
		{ name: 'English', id: 'en' },
		{ name: 'Italiano', id: 'it' },
		{ name: 'Français', id: 'fr' },
	],
}
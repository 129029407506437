import styled from "styled-components";
import theme from "../styled/Theme";
import { H2, H5, Text } from "../styled/Typo";

export const Background = styled.div`
  padding: 1rem;

  @media (min-width: ${theme.breakpoints.sm}) {padding-top: 24px}
  @media (min-width: ${theme.breakpoints.md}) {padding-top: 40px}
  @media (min-width: ${theme.breakpoints.xl}) {padding-top: 95px}

  @media (min-width: ${theme.breakpoints.sm}) {padding-bottom: 40px}
  @media (min-width: ${theme.breakpoints.md}) {padding-bottom: 40px}
  @media (min-width: ${theme.breakpoints.xl}) {padding-bottom: 113px}
	
	p + p {
		margin-top: 2em;
	}
`

export const StyledH5 = styled(H5)`
	margin-top: 0;
  @media (min-width: ${theme.breakpoints.sm}) {margin-bottom: 0px}
  @media (min-width: ${theme.breakpoints.md}) {margin-bottom: 8px}
  @media (min-width: ${theme.breakpoints.xl}) {margin-bottom: 8px}
	text-transform: uppercase;
`

export const StyledH2 = styled(H2)`
  margin-top: 0;
  @media (min-width: ${theme.breakpoints.sm}) {margin-bottom: 16px}
  @media (min-width: ${theme.breakpoints.md}) {margin-bottom: 24px}
  @media (min-width: ${theme.breakpoints.xl}) {margin-bottom: 40px}
	text-transform: uppercase;
`

export const ReadMore = styled(Text)`
	text-decoration: underline;
  @media (min-width: ${theme.breakpoints.sm}) {margin-top: 8px}
  @media (min-width: ${theme.breakpoints.md}) {margin-top: 24px}
	
  @media (min-width: ${theme.breakpoints.xl}) {display: none}
`
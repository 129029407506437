import { useDispatch, useSelector } from "react-redux";
import { LanguageStyled, StyledArrow } from "./Styles";
import { Dropdown } from "react-bootstrap";
import { Text } from "../styled/Typo";
import React from "react";
import ArrowDown from '../../images/arrow_down.svg'
import { navigate } from "gatsby-link";

export const LangDropDown = ({languages}) => {

	const { lang } = useSelector(state => state.language)
	const dispatch = useDispatch()

	const activeLang = languages.filter(language => lang === language.id)
	const otherLang = languages.filter(language => lang !== language.id)

	const setActive = (e) => {
		navigate(`/${e.target.dataset.id !== 'de' ? e.target.dataset.id : ''}`)
	}

	return (
			<LanguageStyled>
				<Dropdown>
					<Dropdown.Toggle>
						{activeLang.map(language => <Text key={language.name}>{language.name}</Text>)}
						<StyledArrow>
							<img src={ArrowDown} alt="arrowDown"/>
						</StyledArrow>
					</Dropdown.Toggle>

					<Dropdown.Menu>
						{otherLang.map(language =>  <Dropdown.Item  data-id={language.id} key={language.name} onClick={setActive}>{language.name}</Dropdown.Item> )}
					</Dropdown.Menu>
				</Dropdown>
			</LanguageStyled>
	)
}
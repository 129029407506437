import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import { Button } from "../styled/Button";
import { H3, H5, Text } from "../styled/Typo";
import { Container } from "../styled/Container";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import theme from "../styled/Theme";
import { useInView } from "react-intersection-observer";

const StyledFormLabel = styled( Form.Label )`
  color: ${theme.color.grey};
  margin-bottom: 9px;
`

const StyledFormControl = styled( Form.Control )`
  color: ${theme.color.grey} !important;
  background-color: transparent !important;
  border: 1px solid ${theme.color.grey} !important;
  border-radius: 0px !important;
  min-height: 59px;
  margin-bottom: 2rem;


  &[type="textarea"] {
    min-height: 240px;
  }

`

const StyledButton = styled( Button )`
  margin-top: 2rem;
  align-self: flex-end;
`

const StyledContainer = styled( Container )`
  max-width: 1319px;
  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: 86px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: 86px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-top: 92px
  }
  @media (min-width: ${theme.breakpoints.sm}) {padding: 0 32px}
  @media (min-width: ${theme.breakpoints.md}) {padding: 0 32px}
  @media (min-width: ${theme.breakpoints.xl}) {padding: 0 1rem}
`

const FormField = ({ id, required, type, label, span, onChange }) => {
	const { lang } = useSelector( state => state.language )

	return (
			<Form.Group className={`col-xl-${span} col-lg-12 flex-column d-flex`} controlId={label[lang]}>
				<StyledFormLabel>{label[lang]}</StyledFormLabel>
				{type === 'textarea' ? <StyledFormControl name={id} required={required} type={type} onChange={onChange} as={'textarea'}/> : <StyledFormControl name={id} required={required} onChange={onChange} type={type}/>}

			</Form.Group>
	)
}

const ContactForm = ({ success, overline, headline, notice, fields, button }) => {
	const { lang } = useSelector( state => state.language )
	const dispatch = useDispatch()
	const [send, setSend] = useState(false)
	const { ref, inView, entry } = useInView();

	useEffect(() => {
		dispatch({type: 'contact_view', inView})
	}, [inView])

	const formSubmit = (e) => {
		e.preventDefault()
		fetch('send.php', {
			method: 'POST',
			body: new FormData(e.target)
		}).then(e => {
			if (e.status === 200) setSend(true)
		})
		// console.log(new FormData(e.target).get('mail'))
	}
	return (
			<StyledContainer ref={ref}>
				<Row>
					<Col xl={{ span: 4 }} lg={{ span: 12 }}>
						<H5 uppercase primary>{overline[lang]}</H5>

						<H3 uppercase dangerouslySetInnerHTML={{__html: headline[lang]}} />
					</Col>
					{send ? (
							<Col xl={{ span: 8 }} md={{ span: 12 }}>
								<H3>{success[lang]}</H3>
							</Col>
					) : (
							<Col xl={{ span: 8 }} md={{ span: 12 }}>

								<Form onSubmit={formSubmit}>
									<div className="row">
										{fields.map( (field, key) => <FormField key={key} {...field}/> )}
									</div>
									<Text>{notice[lang]}</Text>
									<div className="d-flex justify-content-start justify-content-xl-end">
										<StyledButton as={'button'}>{button[lang]}</StyledButton>
									</div>
								</Form>
							</Col>
					)}

				</Row>

			</StyledContainer>
	);
};

export default ContactForm;
import React from "react";
import styled from "styled-components";
import theme from "../styled/Theme";


const StyledLink = styled.a`
	color: white;
	display: block;
	text-decoration: none;

  @media (min-width: ${theme.breakpoints.sm}) {line-height: 22px}
  @media (min-width: ${theme.breakpoints.md}) {line-height: 26px}
	
	&:hover {
		color: ${theme.color.primary};
		div {
      color: ${theme.color.primary};
		}
	}
`

export const ExternalLink = ({link, children}) => {
	// console.log(link)
	return (
			<StyledLink href={link} target={'_blank'}>{children}</StyledLink>
	)
}
import styled from "styled-components"
import theme from "./Theme";

export const Button = styled.div`
	display: inline-block;
	background: ${theme.color.primary};
  font-family: ${theme.font.main};
  font-weight: ${theme.font.weight.bold};
	padding: 8px 51px;
	color: ${theme.color.black};
	border: 0;
	box-sizing: border-box;
	appearance: none;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 24px;
	text-decoration: none;
	transition: .6s ease;
	&:hover {
    color: ${theme.color.black} !important;
		transform: scale(1.1);
  }
`



const theme = {
	color: {
		primary: '#FFDD00',
		black: '#12110C',
		grey: '#9C9E9F'
	},
	breakpoints: {
		xl: '1200px',
		md: '768px',
		sm: '0px'
	},
	font: {
		main: 'Arial',
		weight: {
			bold: 700,
			regular: 400
		}
	}
};


export default theme
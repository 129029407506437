import React from 'react';
import { H4, Text } from "../../styled/Typo";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import theme from "../../styled/Theme";


const StyledH4 = styled( H4 )`
  margin: 0;
  max-width: 200px;
`
const StyledText = styled( Text )`
  margin: 0;
  max-width: 200px;
`

const ImageWrap = styled.div`
	
  @media (min-width: ${theme.breakpoints.sm}) {
    width: 166px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    width: 240px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    width: 268px
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    height: 166px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    height: 240px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    height: 268px
  }
  background-image: radial-gradient(circle at 50% 50%, #262525 0%, #12110C 46%);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: 0px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 0px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-bottom: 32px
  }

  img {
    width: 180px;
    height: 180px;
    object-fit: contain;
    transition: .6s ease;

  }

  transition: .6s ease;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`

const StyledProductBox = styled.div`
	cursor: pointer;
	
  @media (min-width: ${theme.breakpoints.sm}) {padding-top: 2rem;}
  @media (min-width: ${theme.breakpoints.md}) {padding-top: 1rem;}
	margin-left: 1rem;
  ${props => props.active === true && css`
    @media (min-width: 900px) {border-top: 1px solid white}
  `}
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`

const ProductBox = ({ id, name, excerpt, image, url, onClick, uppercase = true }) => {
	const { lang } = useSelector( state => state.language )
	const activeTabs = useSelector( state => state.activeTabs )


	return (
			<StyledProductBox active={activeTabs.some( tab => tab.product === id )} onClick={onClick}>
				<ImageWrap>
					<img src={image}/>
				</ImageWrap>
				<StyledH4 uppercase primary>{name[lang]}</StyledH4>
				{uppercase === true ? (
						<StyledH4 uppercase large dangerouslySetInnerHTML={{__html: excerpt[lang]}}/>
				) : (
						<StyledText large dangerouslySetInnerHTML={{__html: excerpt[lang]}}/>
				)}

			</StyledProductBox>
	);
};

export default ProductBox;
import RC4108 from '../images/_RC4108_3D-left.png'
import RC4560 from '../images/_RC4560_3D-left.png'
import RC4660 from '../images/_RC4660_3D-left.png'
import RC4610 from '../images/_RC4610_3D-left.png'
import RC4770 from "../images/_RC4770_3D-left.png";
import RC4710 from "../images/_RC4710_3D-left.png";
import RC4710big from "../images/_RC4710_big.png";
import RC4770big from "../images/_RC4770_big.png";
import RC4610big from "../images/_RC4610_big.png";
import RC4660big from "../images/_RC4660_big.png";
import RC4108big from "../images/_RC4108_big.png";
import RC4560Big from '../images/_RC4560_big.png'
import Header from "../images/Header.jpg";
import Bgone from "../images/BGone.jpg";
import Bgtwo from "../images/BGtwo.jpg";

import PDFde from '../images/RC-Drills_6p_leaflet_final_open_german.pdf'
import PDFen from '../images/RC-Drills_6p_leaflet_final_open_english.pdf'
import PDFit from '../images/RC-Drills_6p_leaflet_final_open_italian.pdf'
import PDFpl from '../images/RC-Drills_6p_leaflet_final_open_polish.pdf'
import PDFfr from '../images/RC-Drills_6p_leaflet_final_open_french.pdf'


export const content = [
	{
		id: 1,
		type: 'Header',
		overline: {
			de: 'Unterschiedliche Leistungsklassen und Werkzeugformen',
			en: 'DIFFERENT POWER CLASSES AND TOOL SHAPES',
			it: 'DIVERSE CLASSI DI POTENZA E FORME DI UTENSILI',
			fr: "DIFFÉRENTES CLASSES DE PUISSANCE ET FORMES D'OUTILS"
		},
		headline: {
			de: 'Neue Rodcraft Bohrmaschinen und Drehschrauber',
			en: 'New Rodcraft drills and screwdrivers',
			it: 'NUOVI TRAPANI E AVVITATORI RODCRAFT',
			fr: 'Nouvelles perceuses et visseuses RODCRAFT',
		},
		image: Header
	},
	{
		id: 2,
		type: 'IntroText',
		overline: {
			de: 'RC4xxx',
			en: 'RC4xxx',
			it: 'RC4xxx',
			fr: 'RC4xxx',
		},
		headline: {
			de: 'RODCRAFT Druckluft-Bohrmaschinen und Drehschrauber',
			en: 'RODCRAFT pneumatic drills and screwdrivers',
			it: 'Trapani e avvitatori pneumatici',
			fr: 'Perceuses et visseuses à air comprimé',
		},
		copy: {
			de: '<p>Mit den vier Druckluft-Bohrmaschinen und den zwei Druckluft-Drehschraubern von RODCRAFT sind Werkstätten für Fahrzeugservices oder Betriebe im Bereich der industriellen Instandsetzung und Montage komplett ausgerüstst – flexibel, ergonomisch und mit hoher Lebensdauer.</p><p>Alle Modelle garantieren mit dem leichten und kälteisolierenden Komposit-Gehäuse ein angenehmes und ermüdungsfreies Arbeiten, auch im Dauerbetrieb. Ausgestattet mit einem Schnellspannbohrfutter beziehungsweise einer Bit-Aufnahme mit Innensechskannt ist der Werkzeugwechsel unkompliziert und in wenigen Sekunden durchgeführt.</p><p>Die extrem feine Abstimmung des Auslseknopfs garantiert sowohl beim Schrauben als auch beim Bohren präzises Arbeiten. Drei der Druckluft-Bohrmaschinen bieten dem Anwender eine einfache und handliche Umschaltung zwischen Vorwärts- und Rückwärtslauf, ohne das Werkzeug absetzen zu müssen. Für einen genau abgestimmten Anzug der Schrauben ist das Drehmoment der Druckluft-Drehschrauber stufenlos und ohne Zerlegen des Kupplungsgehäuses justierbar.</p>',
			en: '<p>With the four pneumatic drills and the two pneumatic screwdrivers from RODCRAFT, workshops for vehicle servicing or companies in the field of industrial repair and assembly are fully equipped – flexible, ergonomic and with a long service life.</p><p>With the lightweight and cold-insulating composite housing, all models guarantee comfortable and fatigue-free work, even under continuous operation. Equipped with a keyless chuck or a bit holder with hexagon socket, changing tools is uncomplicated and accomplished in a few seconds. The extremely fine adjustment of the release button guarantees precise work both when screwing and drilling Three of the pneumatic drills offer the user a simple and handy switchover between forward and reverse rotation without having to put down the tool. For a precisely coordinated tightening of the screws, the torque of the pneumatic screwdriver can be continuously adjusted without dismantling the coupling housing.</p>',
			it: '<p>Con i quattro trapani pneumatici e i due avvitatori pneumatici di RODCRAFT, le officine per la manutenzione dei veicoli o le aziende nell’ambito della riparazione e del montaggio industriale sono completamente attrezzate – flessibili, ergonomici e co una lunga durata utile.</p> <p>Con il loro alloggiamento composito leggero e isolante dal freddo, tutti i modelli garantiscono un lavoro confortevole e senza fatica, anche nel funzionamento continuo. Dotato di un mandrino per punte a serraggio rapido o di un porta-inserti con esagono cavo, il cambio degli utensili è semplice e può essere effettuato in pochi secondi. La regolazione estremamente fine del pulsante di rilascio garantisce un lavoro preciso sia nell’avvitamento che nella foratura. Tre dei trapani pneumatici offrono all’utente una commutazione semplice e pratica tra il funzionamento in avanti e quello inverso senza dover appoggiare l’utensile. Per un serraggio precisamente regolato delle viti, la coppia degli avvitatori pneumatici è variabile e può essere regolata senza smontare la scatola della frizione.</p>',
			fr: '<p>Avec les quatre perceuses à air comprimé et les deux visseuses à air comprimé de RODCRAFT, les garages sont bien équipés pour les entretiens de véhicules ou les entreprises du secteur de la mise en service et du montage industriels sont bien équipés - flexibles, ergonomiques et avec une longue durée de vie utile.</p><p>Grâce au boîtier composite léger et isolant contre le froid,tous les modèles garantissent un travail agréable et peufatigant, même en régime permanent. Équipé d’un mandrinautoserrant, ou d’un logement d’embout avec six pans creux ,l’outil se remplace tout simplement en quelques secondes. Leréglage très minutieux de la détente de mise à feu garantit untravail de précision tant pour le vissage que pour le perçage.Trois des perceuses à air comprimés offrent à l’utilisateurune commutation facile et pratique entre la marche avant etarrière sans avoir à poser l’outil.Pour un serrage de précision des vis, le couple de la visseuseà air comprimé est réglable en continu sans avoir à démonterle carter d’emballage</p>'
		},
		buttonText: {
			de: 'Weiterlesen',
			en: 'Read more',
			it: 'Continua',
			fr: 'Continuer',
		}
	},
	{
		id: 3,
		type: 'ImageSection',
		overline: {
			de: 'RC4xxx',
			en: 'RC4xxx',
			fr: 'RC4xxx',
			it: 'RC4xxx',
		},
		headline: {
			de: 'DRUCKLUFT-BOHRMASCHINEN',
			en: 'pneumatic drills',
			it: 'Trapani pneumatici',
			fr: 'Perceuses à air comprimé',
		},
		image: Bgone
	},
	{
		id: 4, type: 'ProductList', products: [
			{
				id: 'RC4108',
				name: {
					de: 'RC4108',
					en: 'RC4108',
					it: 'RC4108',
					fr: 'RC4108',
				},
				excerpt: {
					de: '2000&nbsp;1/min, 10&nbsp;mm (vorw./rückw.)',
					en: '2000&nbsp;RPM, 10&nbsp;MM (FORWARD/REVERSE)',
					it: '2000&nbsp;RPM, 10&nbsp;MM (AVANTI/INDIETRO)',
					fr: '2000&nbsp;TR/MIN, 10&nbsp;MM (Réversible)',
				},
				image: RC4108,
				url: {
					de: 'https://www.rodcraft.com/de/8951000422.html',
					en: 'https://www.rodcraft.com/gb/8951000422.html',
					it: 'https://www.rodcraft.com/it/8951000422.html',
					fr: 'https://www.rodcraft.com/fr/8951000422.html',
				}
			},
			{
				id: 'RC4560',
				name: {
					de: 'RC4560',
					en: 'RC4560',
					it: 'RC4560',
					fr: 'RC4560',
				},
				excerpt: {
					de: '800&nbsp;1/min, 13&nbsp;mm (vorw./rückw.)',
					en: '800&nbsp;RPM, 13&nbsp;MM (FORWARD/REVERSE)',
					fr: '800&nbsp;TR/MN, 13&nbsp;MM (Réversible) ',
					it: '800&nbsp;RPM, 13&nbsp;MM (AVANTI/INDIETRO)',
				},
				image: RC4560,
				url: {
					de: 'https://www.rodcraft.com/de/8951000423.html',
					en: 'https://www.rodcraft.com/gb/8951000423.html',
					it: 'https://www.rodcraft.com/it/8951000423.html',
					fr: 'https://www.rodcraft.com/fr/8951000423.html',
				}
			},
			{
				id: 'RC4660',
				name: {
					de: 'RC4660',
					en: 'RC4660',
					it: 'RC4660',
					fr: 'RC4660',
				},
				excerpt: {
					de: '1400&nbsp;1/min, 10&nbsp;mm (vorw./rückw.)',
					en: '1400&nbsp;RPM, 10&nbsp;MM (FORWARD/REVERSE)',
					fr: '1400&nbsp;TR/MN, 10&nbsp;MM (Réversible)',
					it: '1400&nbsp;RPM, 10&nbsp;MM (AVANTI/INDIETRO)',
				},
				image: RC4660,
				url: {
					de: 'https://www.rodcraft.com/de/8951000424.html',
					en: 'https://www.rodcraft.com/gb/8951000424.html',
					it: 'https://www.rodcraft.com/it/8951000424.html',
					fr: 'https://www.rodcraft.com/fr/8951000424.html',
				}
			},
			{
				id: 'RC4610',
				name: {
					de: 'RC4610',
					en: 'RC4610',
					it: 'RC4610',
					fr: 'RC4610',
				},
				excerpt: {
					de: '2500&nbsp;1/min, 10&nbsp;mm',
					en: '2500&nbsp;RPM, 10&nbsp;MM',
					it: '2500&nbsp;RPM, 10&nbsp;MM',
					fr: '2500&nbsp;TR/MN, 10&nbsp;MM',
				},
				image: RC4610,
				url: {
					de: 'https://www.rodcraft.com/de/8951000425.html',
					en: 'https://www.rodcraft.com/gb/8951000425.html',
					it: 'https://www.rodcraft.com/it/8951000425.html',
					fr: 'https://www.rodcraft.com/fr/8951000425.html',
				}
			}
		]
	},
	{
		id: 'R-RC4108',
		type: 'Register',
		productId: 'RC4108',
		overline: {
			de: 'RC4108',
			en: 'RC4108',
			it: 'RC4108',
			fr: 'RC4108',
		},
		headline: {
			de: 'Bohrmaschine in Pistolenausführung',
			en: 'Pistol-design drill',
			it: 'Trapani a pistola',
			fr: 'Perceuses en version pistolet',
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000422.html',
			en: 'https://www.rodcraft.com/gb/8951000422.html',
			it: 'https://www.rodcraft.com/it/8951000422.html',
			fr: 'https://www.rodcraft.com/fr/8951000422.html',
		},
		pdf: {
			de: PDFde,
			en: PDFen,
			it: PDFit,
			pl: PDFpl,
			fr: PDFfr
		},
		image: RC4108big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
				},
				points: [
					{
						headline: {
							de: 'Hohe Lebensdauer',
							en: 'Long service life',
							it: 'Elevata durata utile',
							fr: 'Longue durée de vie utile',
						},
						copy: {
							de: 'Mit hochwertigem Schnellspannfutter von Jacobs®',
							en: 'With high-quality quick-change chuck from Jacobs®',
							it: 'Con mandrino per punte a cambio rapido di alta qualità di Jacobs®',
							fr: 'Avec mandrin à échange rapide de bonne qualité Jacobs®',
						},
						x: '10%',
						y: '10%'
					}, {
						headline: {
							de: 'Exakte Drehzahl-kontrolle',
							en: 'Precise speed control',
							it: 'Controllo preciso della velocità',
							fr: 'Contrôle précis de la vitesse de rotation',
						},
						copy: {
							de: 'Feinfühliger Auslöser für mehr Kontrolle beim Anbohren',
							en: 'Sensitive trigger for more control when drilling',
							it: 'Grilletto sensibile per un maggiore controllo durante la foratura',
							fr: 'Gâchette sensible pour une meilleure maîtrise pendant le perçage',
						},
						x: '60%',
						y: '35%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité',
				},
				points: [
					{
						headline: {
							de: 'Anwender-freundlich',
							en: 'User-friendly',
							it: 'User frienldy',
							fr: 'Convivial',
						},
						copy: {
							de: 'Abluftführung in Richtung Griffende mit zusätzlichem Schalldämpfer zur Geräuschreduzierung',
							en: 'User-friendly Exhaust air ducting towards the end of the handle with an additional silencer to reduce noise',
							it: 'User friendly Canalizzazione dell’aria di scarico in direzione dell’estremità del manico con silenziatore aggiuntivo per la riduzione del rumore',
							fr: 'Guidage d’évacuation en direction de l’extrémité de la poignée avec silencieux supplémentaire pour réduire le bruit',
						},
						x: '90%',
						y: '95%'
					}
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
				},
				points: [
					{
						headline: {
							de: 'Hervorragendes Handling',
							en: 'Excellent handling',
							it: 'Maneggio eccellente',
							fr: 'Maniabilité exceptionnelle',
						},
						copy: {
							de: 'Flache Gehäuserückseite für bessere Druckausübung beim Bohren und Gewindeschneiden',
							en: '<ul><li>The additional handle is lightweight and individually adjustable by 360 degrees (model with 13 mm and 800 rpm / RC4560)</li><li> Flat back of the housing for better pressure exertion when drilling and reaming</li></ul>',
							it: '<ul><li>Il manico supplementare è leggero e regolabile individualmente a 360 gradi (modello da 13 mm e 800 rpm / RC4560) </li><li> Parte posteriore piatta dell‘alloggiamento per un migliore esercizio della pressione durante la foratura e la maschiatura</li></ul>',
							fr: '<ul><li>– La poignée supplémentaire a un réglage facile à 360 degrés (modèle avec 13 mm et 800 Umin / RC4560</li><li> Face arrière plate pour mieux exercer la pression pendant le perçage et le filetage</li></ul>',
						},
						x: '53%',
						y: '8%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
						},
						copy: {
							de: '<ul><li>Das schlanke Gehäuse liegt perfekt in der Hand</li><li>Rechts-/Linksschalter direkt neben dem Auslöseknopf</li></ul>',
							en: '<ul><li>The slim housing fits perfectly in the hand</li><li>Right / left switch directly next to the release button</li></ul>',
							it: '<ul><li>L’alloggiamento sottile si adatta perfettamente alla mano</li><li> Interruttore corsa a sinistra e destra direttamente accanto al tasto di rilascio</li></ul>',
							fr: '<ul><li>Le boîtier fin se tient bien en main</li><li>Commutateur droit/gauche juste à côté de la gâchette</li></ul>',
						},
						x: '82%',
						y: '63%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High degree of comfort',
							it: 'Elevato comfort',
							fr: 'Grand confort',
						},
						copy: {
							de: '<ul><li> Kälteisolierendes und leichtes Komposit-Gehäuse</li><li>Geringe Ermüdung durch gute Gewichtsbalance und geringes Gewicht von nur 1,1kg (RC4108) bis 1,3kg (RC4560)</li></ul>',
							en: '<ul><li> Cold-insulating and lightweight composite housing</li><li>Low fatigue due to good weight balance and low weight of only 1.1 kg (RC4108) to 1.3 kg (RC4560)</li></ul>',
							it: '<ul><li> Alloggiamento compito leggero e isolante dal freddo</li><li>Poca fatica grazie al buon bilanciamento del peso e al peso ridotto di soli 1,1 kg (RC4108) fino a 1,3 kg (RC4560)</li></ul>',
							fr: '<ul><li>Carter composite léger et isolant contre le froid</li><li>Moindre fatigue grâce au bon équilibre du poids de seulement 1,1 kg (RC4108) à 1,3kg (RC4560)</li></ul>',
						},
						x: '89%',
						y: '40%'
					}
				]
			},
		]
	},
	{
		id: 'R-RC4560',
		type: 'Register',
		productId: 'RC4560',
		overline: {
			de: 'RC4560',
			en: 'RC4560',
			it: 'RC4560',
		},
		headline: {
			de: 'Bohrmaschine in Pistolenausführung',
			en: 'Pistol-design drill',
			it: 'Trapani a pistola',
			fr: 'Perceuses en version pistolet',
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000423.html',
			en: 'https://www.rodcraft.com/gb/8951000423.html',
			it: 'https://www.rodcraft.com/it/8951000423.html',
			fr: 'https://www.rodcraft.com/fr/8951000423.html',
		},
		pdf: {
			de: PDFde,
			en: PDFen,
			it: PDFit,
			pl: PDFpl,
			fr: PDFfr
		},
		image: RC4560Big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
				},
				points: [
					{
						headline: {
							de: 'Hohe Lebensdauer',
							en: 'Long service life',
							it: 'Elevata durata utile',
							fr: 'Longue durée de vie utile',
						},
						copy: {
							de: 'Mit hochwertigem Schnellspannfutter von Jacobs®',
							en: 'With high-quality quick-change chuck from Jacobs®',
							it: 'Con mandrino per punte a cambio rapido di alta qualità di Jacobs®',
							fr: 'Avec mandrin à échange rapide de bonne qualité Jacobs®',
						},
						x: '10%',
						y: '35%'
					}, {
						headline: {
							de: 'Exakte Drehzahl-kontrolle',
							en: 'Precise speed control',
							it: 'Controllo preciso della velocità',
							fr: 'Contrôle précis de la vitesse de rotation',
						},
						copy: {
							de: 'Feinfühliger Auslöser für mehr Kontrolle beim Anbohren',
							en: 'Sensitive trigger for more control when drilling',
							it: 'Grilletto sensibile per un maggiore controllo durante la foratura',
							fr: 'Gâchette sensible pour une meilleure maîtrise pendant le perçage',
						},
						x: '60%',
						y: '55%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza'
				},
				points: [
					{
						headline: {
							de: 'Anwender-freundlich',
							en: 'User-friendly',
							it: 'User frienldy',
							fr: 'Convivial',
						},
						copy: {
							de: 'Abluftführung in Richtung Griffende mit zusätzlichem Schalldämpfer zur Geräuschreduzierung',
							en: 'User-friendly Exhaust air ducting towards the end of the handle with an additional silencer to reduce noise',
							it: 'User friendly Canalizzazione dell’aria di scarico in direzione dell’estremità del manico con silenziatore aggiuntivo per la riduzione del rumore',
							fr: 'Guidage d’évacuation en direction de l’extrémité de la poignée avec silencieux supplémentaire pour réduire le bruit',
						},
						x: '90%',
						y: '95%'
					}
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
				},
				points: [
					{
						headline: {
							de: 'Hervorragendes Handling',
							en: 'Excellent handling',
							it: 'Maneggio eccellente',
							fr: 'Maniabilité exceptionnelle',
						},
						copy: {
							de: 'Flache Gehäuserückseite für bessere Druckausübung beim Bohren und Gewindeschneiden',
							en: '<ul><li>The additional handle is lightweight and individually adjustable by 360 degrees (model with 13 mm and 800 rpm / RC4560)</li><li> Flat back of the housing for better pressure exertion when drilling and reaming</li></ul>',
							it: '<ul><li>Il manico supplementare è leggero e regolabile individualmente a 360 gradi (modello da 13 mm e 800 rpm / RC4560) </li><li> Parte posteriore piatta dell‘alloggiamento per un migliore esercizio della pressione durante la foratura e la maschiatura</li></ul>',
							fr: '<ul><li>La poignée supplémentaire a un réglage facile à 360 degrés (modèle avec 13 mm et 800 Umin / RC4560</li><li> Face arrière plate pour mieux exercer la pression pendant le perçage et le filetage</li></ul>',
						},
						x: '51%',
						y: '8%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
						},
						copy: {
							de: '<ul><li>Das schlanke Gehäuse liegt perfekt in der Hand</li><li>Rechts-/Linksschalter direkt neben dem Auslöseknopf</li></ul>',
							en: '<ul><li>The slim housing fits perfectly in the hand</li><li>Right / left switch directly next to the release button</li></ul>',
							it: '<ul><li>L’alloggiamento sottile si adatta perfettamente alla mano</li><li> Interruttore corsa a sinistra e destra direttamente accanto al tasto di rilascio</li></ul>',
							fr: '<ul><li>Le boîtier fin se tient bien en main</li><li>Commutateur droit/gauche juste à côté de la gâchette</li></ul>',
						},
						x: '82%',
						y: '63%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High degree of comfort',
							it: 'Elevato comfort',
							fr: 'Grand confort',
						},
						copy: {
							de: '<ul><li> Kälteisolierendes und leichtes Komposit-Gehäuse</li><li>Geringe Ermüdung durch gute Gewichtsbalance und geringes Gewicht von nur 1,1kg (RC4108) bis 1,3kg (RC4560)</li></ul>',
							en: '<ul><li> Cold-insulating and lightweight composite housing</li><li>Low fatigue due to good weight balance and low weight of only 1.1 kg (RC4108) to 1.3 kg (RC4560)</li></ul>',
							it: '<ul><li> Alloggiamento compito leggero e isolante dal freddo</li><li>Poca fatica grazie al buon bilanciamento del peso e al peso ridotto di soli 1,1 kg (RC4108) fino a 1,3 kg (RC4560)</li></ul>',
							fr: '<ul><li>Carter composite léger et isolant contre le froid</li><li>Moindre fatigue grâce au bon équilibre du poids de seulement 1,1 kg (RC4108) à 1,3kg (RC4560)</li></ul>',
						},
						x: '89%',
						y: '80%'
					}
				]
			},
		]
	},
	{
		id: 'R-RC4660',
		type: 'Register',
		productId: 'RC4660',
		overline: {
			de: 'RC4660',
			en: 'RC4660',
			it: 'RC4660',
			fr: 'RC4660',
		},
		headline: {
			de: 'Winkel-Bohrmaschine',
			en: 'Angle drill',
			it: 'Trapani pneumatici',
			fr: 'Perceuses à air comprimé',
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000424.html',
			en: 'https://www.rodcraft.com/gb/8951000424.html',
			it: 'https://www.rodcraft.com/it/8951000424.html',
			fr: 'https://www.rodcraft.com/fr/8951000424.html',
		},
		pdf: {
			de: PDFde,
			en: PDFen,
			it: PDFit,
			pl: PDFpl,
			fr: PDFfr
		},
		image: RC4660big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
				},
				points: [
					{
						headline: {
							de: 'Gute Zugänglichkeit',
							en: 'Good accessibility',
							it: 'Ottima accessibilità',
							fr: 'Bonne accessibilité',
						},
						copy: {
							de: 'Kompaktes, niedriges Profil für den leichten Zugang an engen Stellen',
							en: 'Compact, low profile for easy access in cramped locations',
							it: 'Compatto, profilo basso per un facile accesso ai punti più strett',
							fr: 'Profil compact et bas pour un accès facile',
						},
						x: '10%',
						y: '30%'
					}, {
						headline: {
							de: 'Hohe Lebensdauer',
							en: 'Long service life',
							it: 'Elevata durata utile',
							fr: 'Longue durée de vie utile',
						},
						copy: {
							de: 'Mit hochwertigem Schnellspannfutter von Jacobs®',
							en: 'With high-quality quick-change chuck from Jacobs®',
							it: 'Con mandrino per punte a cambio rapido di alta qualità di Jacobs®',
							fr: 'Avec mandrin à échange rapide de bonne qualité Jacobs®',
						},
						x: '10%',
						y: '85%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité'
				},
				points: [
					{
						headline: {
							de: 'Hohe Sicherheit',
							en: 'High degree of Safety',
							it: 'Elevata sicurezza',
							fr: 'Sécurité renforcée',
						},
						copy: {
							de: 'Sicherheitsauslöser für ein kontrolliertes Arbeiten, ohne versehentlichen Anlauf ',
							en: 'Safety trigger for controlled work without accidental start-up',
							it: 'Grilletto di sicurezza per un lavoro controllato senza il rischio di un avvio imprevisto ',
							fr: ' Gâchette de sécurité pour un travail maîtrisé, sans démarrage involontaire',
						},
						x: '57%',
						y: '3%'
					},
					{
						headline: {
							de: 'Anwender-freundlich',
							en: 'User-friendly',
							it: 'User frienldy',
							fr: 'Convivial',
						},
						copy: {
							de: 'Abluftführung in Richtung Griffende mit zusätzlichem Schalldämpfer zur Geräuschreduzierung',
							en: 'User-friendly Exhaust air ducting towards the end of the handle with an additional silencer to reduce noise',
							it: 'User friendly Canalizzazione dell’aria di scarico in direzione dell’estremità del manico con silenziatore aggiuntivo per la riduzione del rumore',
							fr: 'Guidage d’évacuation en direction de l’extrémité de la poignée avec silencieux supplémentaire pour réduire le bruit',
						},
						x: '97%',
						y: '30%'
					},
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
				},
				points: [
					{
						headline: {
							de: 'Leichte Bedienung',
							en: 'Easy operation',
							it: 'Comando facile ',
							fr: 'Facilité d’utilisation',
						},
						copy: {
							de: 'Integrierter Ring für Wechsel von Rechts- auf Linkslauf',
							en: 'Integrated ring for changing from right to left rotation',
							it: 'Comando facile Anello integrato per commutazione corsa destra e sinistra',
							fr: 'Commutateur intégré pour passer de la marche à droite à la marche à gauche',
						},
						x: '72%',
						y: '30%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High degree of comfort',
							it: 'Elevato comfort',
							fr: 'Grand confort',
						},
						copy: {
							de: '<ul><li> Kälteisolierendes und leichtes Komposit-Gehäuse</li><li>Geringe Ermüdung durch gute Gewichtsbalance und geringes Gewicht von nur 1,1kg (RC4108) bis 1,3kg (RC4560)</li></ul>',
							en: '<ul><li> Cold-insulating and lightweight composite housing</li><li>Low fatigue due to good weight balance and low weight of only 1.1 kg (RC4108) to 1.3 kg (RC4560)</li></ul>',
							it: '<ul><li> Alloggiamento compito leggero e isolante dal freddo</li><li>Poca fatica grazie al buon bilanciamento del peso e al peso ridotto di soli 1,1 kg (RC4108) fino a 1,3 kg (RC4560)</li></ul>',
							fr: '<ul><li>Carter composite léger et isolant contre le froid</li><li>Moindre fatigue grâce au bon équilibre du poids de seulement 1,1 kg (RC4108) à 1,3kg (RC4560)</li></ul>',
						},
						x: '40%',
						y: '40%'
					},
				]
			},
		]
	},
	{
		id: 'R-RC4610',
		type: 'Register',
		productId: 'RC4610',
		overline: {
			de: 'RC4610',
			en: 'RC4610',
			it: 'RC4610',
			fr: 'RC4610',
		},
		headline: {
			de: 'Stab-Bohrmaschine',
			en: 'Straight drill',
			it: 'Straight drill',
			fr: 'Straight drill',
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000425.html',
			en: 'https://www.rodcraft.com/en/8951000425.html',
			it: 'https://www.rodcraft.com/it/8951000425.html',
			fr: 'https://www.rodcraft.com/fr/8951000425.html',
		},
		pdf: {
			de: PDFde,
			en: PDFen,
			it: PDFit,
			pl: PDFpl,
			fr: PDFfr
		},
		image: RC4610big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
				},
				points: [
					{
						headline: {
							de: 'Hohe Lebensdauer',
							en: 'Long service life',
							it: 'Elevata durata utile',
							fr: 'Longue durée de vie utile',
						},
						copy: {
							de: 'Mit hochwertigem Schnellspannfutter von Jacobs®',
							en: 'With high-quality quick-change chuck from Jacobs®',
							it: 'Con mandrino per punte a cambio rapido di alta qualità di Jacobs®',
							fr: 'Avec mandrin à échange rapide de bonne qualité Jacobs®',
						},
						x: '10%',
						y: '29%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité'
				},
				points: [
					{
						headline: {
							de: 'Hohe Sicherheit',
							en: 'High degree of Safety',
							it: 'Elevata sicurezza',
							fr: 'Sécurité renforcée',
						},
						copy: {
							de: 'Sicherheitsauslöser für ein kontrolliertes Arbeiten, ohne versehentlichen Anlauf ',
							en: 'Safety trigger for controlled work without accidental start-up',
							it: 'Grilletto di sicurezza per un lavoro controllato senza il rischio di un avvio imprevisto ',
							fr: 'Gâchette de sécurité pour un travail maîtrisé, sans démarrage involontaire',
						},
						x: '63%',
						y: '0%'
					},
					{
						headline: {
							de: 'Anwender-freundlich',
							en: 'User-friendly',
							it: 'User frienldy',
							fr: 'Convivial',
						},
						copy: {
							de: 'Abluftführung in Richtung Griffende mit zusätzlichem Schalldämpfer zur Geräuschreduzierung',
							en: 'User-friendly Exhaust air ducting towards the end of the handle with an additional silencer to reduce noise',
							it: 'User friendly Canalizzazione dell’aria di scarico in direzione dell’estremità del manico con silenziatore aggiuntivo per la riduzione del rumore',
							fr: 'Guidage d’évacuation en direction de l’extrémité de la poignée avec silencieux supplémentaire pour réduire le bruit',
						},
						x: '94%',
						y: '30%'
					},
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomia',
				},
				points: [
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High degree of comfort',
							it: 'Elevato comfort',
							fr: 'Grand confort',
						},
						copy: {
							de: '<ul><li>Kälteisolierendes und leichtes Komposit-Gehäuse</li><li>Geringe Ermüdung durch gute Gewichtsbalance und geringes Gewicht von nur 1,1kg (RC4108) bis 1,3kg (RC4560)</li></ul>',
							en: '<ul><li>Cold-insulating and lightweight composite housing</li><li>Low fatigue due to good weight balance and low weight of only 1.1 kg (RC4108) to 1.3 kg (RC4560)</li></ul>',
							it: '<ul><li>Alloggiamento compito leggero e isolante dal freddo</li><li>Poca fatica grazie al buon bilanciamento del peso e al peso ridotto di soli 1,1 kg (RC4108) fino a 1,3 kg (RC4560)</li></ul>',
							fr: '<ul><li>Carter composite léger et isolant contre le froid</li><li>Moindre fatigue grâce au bon équilibre du poids de seulement 1,1 kg (RC4108) à 1,3kg (RC4560)</li></ul>',
						},
						x: '54%',
						y: '46%'
					},
				]
			},
		]
	},
	{
		id: 31,
		type: 'ImageSection',
		overline: {
			de: 'RC4xxx',
			en: 'RC4xxx',
			it: 'RC4xxx',
			fr: 'RC4xxx',
		},
		headline: {
			de: 'Druckluft-DREHSCHRAUBER',
			en: 'pneumatic Screwdriver',
			it: 'avvitatori pneumatici',
			fr: 'visseuses à air comprimé',
		},
		image: Bgtwo
	},
	{
		id: 41, type: 'ProductList', products: [
			{
				id: 'RC4710',
				name: {
					de: 'RC4710',
					en: 'RC4710',
					fr: 'RC4710',
					it: 'RC4710',
				},
				excerpt: {
					de: '2 – 6 Nm, extern justierbar (vorw./rückw.)',
					en: '2 - 6 NM, EXTERNALLY ADJUSTABLE (FORWARD/BACKWARD)',
					fr: '2 - 6 NM, RÉGLABLE DE L\'EXTÉRIEUR (Réversible)',
					it: '2 - 6 NM, REGOLABILE ESTERNAMENTE (AVANTI/INDIETRO)',
				},
				image: RC4710,
				url: {
					de: 'https://www.rodcraft.com/de/8951000426.html',
					en: 'https://www.rodcraft.com/gb/8951000426.html',
					fr: 'https://www.rodcraft.com/fr/8951000426.html',
					it: 'https://www.rodcraft.com/it/8951000426.html',
				}
			},
			{
				id: 'RC4770',
				name: {
					de: 'RC4770',
					en: 'RC4770',
					fr: 'RC4770',
					it: 'RC4770',
				},
				excerpt: {
					de: '2 – 6 Nm, extern justierbar (vorw./rückw.)',
					en: '2 - 6 NM, EXTERNALLY ADJUSTABLE (FORWARD/REVERSE)',
					fr: '2 - 6 NM, RÉGLABLE DE L\'EXTÉRIEUR (Réversible)',
					it: '2 - 6 NM, REGOLABILE ESTERNAMENTE (AVANTI/INDIETRO)',
				},
				image: RC4770,
				url: {
					de: 'https://www.rodcraft.com/de/8951000427.html',
					en: 'https://www.rodcraft.com/gb/8951000427.html',
					fr: 'https://www.rodcraft.com/fr/8951000427.html',
					it: 'https://www.rodcraft.com/it/8951000427.html',
				}
			},
		]
	},
	{
		id: 'R-RC4710',
		type: 'Register',
		productId: 'RC4710',
		overline: {
			de: 'RC4710',
			en: 'RC4710',
			it: 'RC4710',
			fr: 'RC4710',
		},
		headline: {
			de: 'Drehschrauber in Pistolenausführung',
			en: 'Screwdriver in pistol design',
			it: 'Screwdriver in pistol design',
			fr: 'Screwdriver in pistol design',
		},
		url: 'https://www.rodcraft.com/de/8951000426.html',
		pdf: {
			de: PDFde,
			en: PDFen,
			it: PDFit,
			pl: PDFpl,
			fr: PDFfr
		},
		image: RC4710big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
				},
				points: [
					{
						headline: {
							de: 'Schnellwechsel-futter',
							en: 'Optimum Productivity',
							it: 'Ottimale produttività',
							fr: 'Productivité optimale',
						},
						copy: {
							de: '¼” Innensechskant für den schnellen Werkzeugwechsel',
							en: '<ul><li>Quick-change chuck, ¼” exagon socket for quick tool changes</li><li>Fast and precise torque setting, 2 – 6 Nm, no dismantling of the clutch housing necessary</li></ul>',
							it: '<ul><li>Mandrino a cambio rapido, ¼” esagono cavo per la rapida sostituzione dell’utensile</li><li>Regolazione veloce e precisa della coppia, 2 – 6 Nm, non è necessario smontare la scatola della frizione</li></ul>',
							fr: '<ul><li>Mandrin, ¼” six pans creux pour un échange rapide d’outils</li><li>Réglage du couple rapide et précis, 2 – 6 Nm, nul besoin de démonter le carter d’embrayage</li></ul>',
						},
						x: '0%',
						y: '10%'
					},
					{
						headline: {
							de: 'Exakte Drehzahl-kontrolle',
							en: 'Precise speed control',
							it: 'Controllo preciso della velocità',
							fr: 'Contrôle précis de la vitesse de rotation',
						},
						copy: {
							de: 'Feinfühliger Auslöser für mehr Kontrolle beim Anbohren',
							en: 'Sensitive trigger for more control when drilling',
							it: 'Grilletto sensibile per un maggiore controllo durante la foratura',
							fr: 'Gâchette sensible pour une meilleure maîtrise pendant le perçage',
						},
						x: '21%',
						y: '10%'
					},
					{
						headline: {
							de: 'Schnelle und exakte Drehmoment-einstellung',
							en: '',
							it: '',
							fr: 'Contrôle précis de la vitesse de rotation',
						},
						copy: {
							de: '2 – 6 Nm Kein Zerlegen des Kupplungsgehäuses nötig',
							en: 'With high-quality quick-change chuck from Jacobs®',
							it: 'With high-quality quick-change chuck from Jacobs®',
							fr: 'Gâchette sensible et vanne progressive pour un vissage maîtrisé',
						},
						x: '60%',
						y: '35%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité'
				},
				points: [
					{
						headline: {
							de: 'Anwender-freundlich',
							en: 'User-friendly',
							it: 'User frienldy',
							fr: 'Convivial',
						},
						copy: {
							de: 'Abluftführung in Richtung Griffende mit zusätzlichem Schalldämpfer zur Geräuschreduzierung',
							en: 'User-friendly Exhaust air ducting towards the end of the handle with an additional silencer to reduce noise',
							it: 'User friendly Canalizzazione dell’aria di scarico in direzione dell’estremità del manico con silenziatore aggiuntivo per la riduzione del rumore',
							fr: 'Guidage d’évacuation en direction de l’extrémité de la poignée avec silencieux supplémentaire pour réduire le bruit',
						},
						x: '90%',
						y: '90%'
					},
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
				},
				points: [
					{
						headline: {
							de: 'Leichte Bedienung',
							en: 'Easy operation',
							it: 'Comando facile ',
							fr: 'Facilité d’utilisation',
						},
						copy: {
							de: 'Integrierter Ring für Wechsel von Rechts- auf Linkslauf',
							en: 'Integrated ring for changing from right to left rotation',
							it: 'Comando facile Anello integrato per commutazione corsa destra e sinistra',
							fr: 'Commutateur intégré pour passer de la marche à droite à la marche à gauche',
						},
						x: '75%',
						y: '30%'
					},

					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High degree of comfort',
							it: 'Elevato comfort',
							fr: 'Grand confort',
						},
						copy: {
							de: '<ul><li> Kälteisolierendes und leichtes Komposit-Gehäuse</li><li>Geringe Ermüdung durch gute Gewichtsbalance und geringes Gewicht von nur 1,1kg (RC4108) bis 1,3kg (RC4560)</li></ul>',
							en: '<ul><li> Cold-insulating and lightweight composite housing</li><li>Low fatigue due to good weight balance and low weight of only 1.1 kg (RC4108) to 1.3 kg (RC4560)</li></ul>',
							it: '<ul><li> Alloggiamento compito leggero e isolante dal freddo</li><li>Poca fatica grazie al buon bilanciamento del peso e al peso ridotto di soli 1,1 kg (RC4108) fino a 1,3 kg (RC4560)</li></ul>',
							fr: '<ul><li>Carter composite léger et isolant contre le froid</li><li>Moindre fatigue grâce au bon équilibre du poids de seulement 1,1 kg (RC4108) à 1,3kg (RC4560)</li></ul>',
						},
						x: '86%',
						y: '40%'
					},
				]
			},
		]
	},
	{
		id: 'R-RC4770',
		type: 'Register',
		productId: 'RC4770',
		overline: { de: 'RC4770', en: 'RC4770' },
		headline: { de: 'Stab-Drehschrauber', en: 'Straight screwdriver' },
		url: {
			de: 'https://www.rodcraft.com/de/8951000427.html',
			en: 'https://www.rodcraft.com/gb/8951000427.html',
			it: 'https://www.rodcraft.com/it/8951000427.html',
			fr: 'https://www.rodcraft.com/fr/8951000427.html',
		},
		pdf: {
			de: PDFde,
			en: PDFen,
			it: PDFit,
			pl: PDFpl,
			fr: PDFfr
		},
		image: RC4770big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
				},
				points: [
					{
						headline: {
							de: 'Schnellwechsel-futter',
							en: 'Optimum Productivity',
							it: 'Ottimale produttività',
							fr: 'Productivité optimale',
						},
						copy: {
							de: '¼” Innensechskant für den schnellen Werkzeugwechsel',
							en: '<ul><li>Quick-change chuck, ¼” exagon socket for quick tool changes</li><li>Fast and precise torque setting, 2 – 6 Nm, no dismantling of the clutch housing necessary</li></ul>',
							it: '<ul><li>Mandrino a cambio rapido, ¼” esagono cavo per la rapida sostituzione dell’utensile</li><li>Regolazione veloce e precisa della coppia, 2 – 6 Nm, non è necessario smontare la scatola della frizione</li></ul>',
							fr: '<ul><li>Mandrin, ¼” six pans creux pour un échange rapide d’outils</li><li>Réglage du couple rapide et précis, 2 – 6 Nm, nul besoin de démonter le carter d’embrayage</li></ul>',
						},
						x: '0%',
						y: '30%'
					},
					{
						headline: {
							de: 'Exakte Drehzahl-kontrolle',
							en: 'Precise speed control',
							it: 'Controllo preciso della velocità',
							fr: 'Contrôle précis de la vitesse de rotation',
						},
						copy: {
							de: 'Feinfühliger Auslöser für mehr Kontrolle beim Anbohren',
							en: 'Sensitive trigger for more control when drilling',
							it: 'Grilletto sensibile per un maggiore controllo durante la foratura',
							fr: 'Gâchette sensible pour une meilleure maîtrise pendant le perçage',
						},
						x: '69%',
						y: '0%'
					},
					{
						headline: {
							de: 'Schnelle und exakte Drehmoment-einstellung',
							en: '',
							it: 'Controllo preciso della velocità',
						},
						copy: {
							de: '2 – 6 Nm Kein Zerlegen des Kupplungsgehäuses nötig',
							en: 'With high-quality quick-change chuck from Jacobs®',
							it: '2 – 6 Nm, non è necessario smontare la scatola della frizione',
							fr: 'Réglage du couple rapide et précis, 2 – 6 Nm, nul besoin de démonter le carter d’embrayage',
						},
						x: '30%',
						y: '30%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité'
				},
				points: [
					{
						headline: {
							de: 'Anwender-freundlich',
							en: 'User-friendly',
							it: 'User frienldy',
							fr: 'Convivial',
						},
						copy: {
							de: 'Abluftführung in Richtung Griffende mit zusätzlichem Schalldämpfer zur Geräuschreduzierung',
							en: 'User-friendly Exhaust air ducting towards the end of the handle with an additional silencer to reduce noise',
							it: 'User friendly Canalizzazione dell’aria di scarico in direzione dell’estremità del manico con silenziatore aggiuntivo per la riduzione del rumore',
							fr: 'Guidage d’évacuation en direction de l’extrémité de la poignée avec silencieux supplémentaire pour réduire le bruit',
						},
						x: '90%',
						y: '30%'
					},
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
				},
				points: [
					{
						headline: {
							de: 'Leichte Bedienung',
							en: 'Easy operation',
							it: 'Comando facile ',
							fr: 'Facilité d’utilisation',
						},
						copy: {
							de: 'Integrierter Ring für Wechsel von Rechts- auf Linkslauf',
							en: 'Integrated ring for changing from right to left rotation',
							it: 'Comando facile Anello integrato per commutazione corsa destra e sinistra',
							fr: 'Commutateur intégré pour passer de la marche à droite à la marche à gauche',
						},
						x: '75%',
						y: '30%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
						},
						copy: {
							de: '<ul><li>Das schlanke Gehäuse liegt perfekt in der Hand</li><li>Rechts-/Linksschalter direkt neben dem Auslöseknopf</li></ul>',
							en: '<ul><li>The slim housing fits perfectly in the hand</li><li>Right / left switch directly next to the release button</li></ul>',
							it: '<ul><li>L’alloggiamento sottile si adatta perfettamente alla mano</li><li> Interruttore corsa a sinistra e destra direttamente accanto al tasto di rilascio</li></ul>',
							fr: '<ul><li>Le boîtier fin se tient bien en main</li><li>Commutateur droit/gauche juste à côté de la gâchette</li></ul>',
						},
						x: '66%',
						y: '40%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High degree of comfort',
							it: 'Elevato comfort',
							fr: 'Grand confort',
						},
						copy: {
							de: '<ul><li>Kälteisolierendes und leichtes Komposit-Gehäuse</li><li>Geringe Ermüdung durch gute Gewichtsbalance und geringes Gewicht von nur 1,1kg (RC4108) bis 1,3kg (RC4560)</li></ul>',
							en: '<ul><li>Cold-insulating and lightweight composite housing</li><li>Low fatigue due to good weight balance and low weight of only 1.1 kg (RC4108) to 1.3 kg (RC4560)</li></ul>',
							it: '<ul><li>Alloggiamento compito leggero e isolante dal freddo</li><li>Poca fatica grazie al buon bilanciamento del peso e al peso ridotto di soli 1,1 kg (RC4108) fino a 1,3 kg (RC4560)</li></ul>',
							fr: '<ul><li>Carter composite léger et isolant contre le froid</li><li>Moindre fatigue grâce au bon équilibre du poids de seulement 1,1 kg (RC4108) à 1,3kg (RC4560)</li></ul>',
						},
						x: '50%',
						y: '40%'
					},
				]
			},
		]
	},
	{
		id: 5, type: 'ProductGrid',
		overline: {
			de: 'RC4XXX',
			en: 'RC4XXX',
			it: 'RC4XXX',
			fr: 'RC4XXX',
		},
		headline: {
			de: 'Produktübersicht',
			en: 'Product overview',
			it: 'Panoramica del prodotto',
			fr: 'Aperçu des produits',
		},
		products: [
			{
				id: 'a-RC4108',
				name: {
					de: 'RC4108',
					en: 'RC4108',
					it: 'RC4108',
					fr: 'RC4108',
				},
				excerpt: {
					de: '2000&nbsp;1/min, 10&nbsp;mm (vorw./rückw.)',
					en: '2000&nbsp;RPM, 10&nbsp;MM (FORWARD/REVERSE)',
					it: '2000&nbsp;RPM, 10&nbsp;MM (AVANTI/INDIETRO)',
					fr: '2000&nbsp;TR/MIN, 10&nbsp;MM (Réversible)',
				},
				image: RC4108,
				url: {
					de: 'https://www.rodcraft.com/de/8951000422.html',
					en: 'https://www.rodcraft.com/gb/8951000422.html',
					it: 'https://www.rodcraft.com/it/8951000422.html',
					fr: 'https://www.rodcraft.com/de/8951000422.html'
				}
			},
			{
				id: 'a-RC4560',
				name: {
					de: 'RC4560',
					en: 'RC4560',
					it: 'RC4560',
					fr: 'RC4560',
				},
				excerpt: {
					de: '800&nbsp;1/min, 13&nbsp;mm (vorw./rückw.)',
					en: '800&nbsp;RPM, 13&nbsp;MM (FORWARD/REVERSE)',
					fr: '800&nbsp;TR/MN, 13&nbsp;MM (Réversible) ',
					it: '800&nbsp;RPM, 13&nbsp;MM (AVANTI/INDIETRO)',
				},
				image: RC4560,
				url: {
					de: 'https://www.rodcraft.com/de/8951000423.html',
					en: 'https://www.rodcraft.com/de/8951000423.html',
					it: 'https://www.rodcraft.com/de/8951000423.html',
					fr: 'https://www.rodcraft.com/de/8951000423.html'
				}
			},
			{
				id: 'a-RC4660',
				name: {
					de: 'RC4660',
					en: 'RC4660',
					it: 'RC4660',
					fr: 'RC4660',
				},
				excerpt: {
					de: '1400&nbsp;1/min, 10&nbsp;mm (vorw./rückw.)',
					en: '1400&nbsp;RPM, 10&nbsp;MM (FORWARD/REVERSE)',
					fr: '1400&nbsp;TR/MN, 10&nbsp;MM (Réversible)',
					it: '1400&nbsp;RPM, 10&nbsp;MM (AVANTI/INDIETRO)',
				},
				image: RC4660,
				url: {
					de: 'https://www.rodcraft.com/de/8951000424.html',
					en: 'https://www.rodcraft.com/de/8951000424.html',
					it: 'https://www.rodcraft.com/de/8951000424.html',
					fr: 'https://www.rodcraft.com/de/8951000424.html'
				}
			},
			{
				id: 'a-RC4610',
				name: {
					de: 'RC4610',
					en: 'RC4610',
					it: 'RC4610',
					fr: 'RC4610',
				},
				excerpt: {
					de: '2500&nbsp;1/min, 10&nbsp;mm',
					en: '2500&nbsp;RPM, 10&nbsp;MM',
					it: '2500&nbsp;RPM, 10&nbsp;MM',
					fr: '2500&nbsp;TR/MN, 10&nbsp;MM',
				},
				image: RC4610,
				url: {
					de: 'https://www.rodcraft.com/de/8951000425.html',
					en: 'https://www.rodcraft.com/de/8951000425.html',
					it: 'https://www.rodcraft.com/de/8951000425.html',
					fr: 'https://www.rodcraft.com/de/8951000425.html',
				}
			},
			{
				id: 'a-RC4710',
				name: {
					de: 'RC4710',
					en: 'RC4710',
					fr: 'RC4710',
					it: 'RC4710',
				},
				excerpt: {
					de: '2 – 6 Nm, extern justierbar (vorw./rückw.)',
					en: '2 - 6 NM, EXTERNALLY ADJUSTABLE (FORWARD/BACKWARD)',
					fr: '2 - 6 NM, RÉGLABLE DE L\'EXTÉRIEUR (Réversible)',
					it: '2 - 6 NM, REGOLABILE ESTERNAMENTE (AVANTI/INDIETRO)',
				},
				image: RC4710,
				url: {
					de: 'https://www.rodcraft.com/de/8951000426.html',
					en: 'https://www.rodcraft.com/de/8951000426.html',
					fr: 'https://www.rodcraft.com/de/8951000426.html',
					it: 'https://www.rodcraft.com/de/8951000426.html',
				}
			},
			{
				id: 'a-RC4770',
				name: {
					de: 'RC4770',
					en: 'RC4770',
					fr: 'RC4770',
					it: 'RC4770',
				},
				excerpt: {
					de: '2 – 6 Nm, extern justierbar (vorw./rückw.)',
					en: '2 - 6 NM, EXTERNALLY ADJUSTABLE (FORWARD/REVERSE)',
					fr: '2 - 6 NM, RÉGLABLE DE L\'EXTÉRIEUR (Réversible)',
					it: '2 - 6 NM, REGOLABILE ESTERNAMENTE (AVANTI/INDIETRO)',
				},
				image: RC4770,
				url: {
					de: 'https://www.rodcraft.com/de/8951000427.html',
					en: 'https://www.rodcraft.com/de/8951000427.html',
					fr: 'https://www.rodcraft.com/de/8951000427.html',
					it: 'https://www.rodcraft.com/de/8951000427.html',
				}
			},


		]
	},
	{
		id: 6,
		type: 'ContactForm',
		success: {
			de: 'Formular erfolgreich versendet',
			en: 'Form successfully sent',
			fr: 'Formulaire envoyé avec succès',
			it: 'Modulo inviato con successo'
		},
		overline: {
			de: 'Kontakt',
			en: 'Contact',
			fr: 'Contact',
			it: 'Contatto',
		},
		headline: {
			de: 'Interessiert? <br> Schreiben Sie uns!',
			en: 'INTERESTED? <br> CONTACT US!',
			fr: 'INTÉRESSÉ(E) ? <br> CONTACTEZ-NOUS !',
			it: 'INTERESSATO? <br> CONTATTACI!',
		},
		notice: {
			de: '* Alle mit einem Sternchen markierten Felder sind Pflichtfelder',
			en: '* All fields marked with an asterisk are mandatory.',
			fr: "* Tous les champs marqués d'un astérisque sont obligatoires.",
			it: '* Tutti i campi contrassegnati da un asterisco sono obbligatori.',
		},
		button: {
			de: 'Absenden',
			en: 'Send',
			it: 'Invia',
			fr: 'Envoyer',
		},
		fields: [
			{
				id: 'firstname',
				required: true,
				type: 'text',

				label: {
					de: 'Vorname*',
					en: 'First name*',
					fr: 'Prénom*',
					it: 'Nome*',
				},
				span: '6'
			},
			{
				id: 'lastname',
				required: true,
				type: 'text',
				label: {
					de: 'Name*',
					en: 'Last name*',
					fr: 'Nom*',
					it: 'Cognome*',
				},
				span: '6'
			},
			{
				id: 'mail',
				required: true,
				type: 'text',
				label: {
					de: 'E-Mail*',
					en: 'E-Mail*',
					fr: 'E-Mail*',
					it: 'E-Mail*',
				},
				span: '6'
			},
			{
				id: 'phone',
				required: false,
				type: 'text',
				label: {
					de: 'Telefon',
					en: 'Phone',
					fr: 'Téléphone',
					it: 'Telefono',
				},
				span: '6'
			},
			{
				id: 'post',
				required: false,
				type: 'text',
				label: {
					de: 'PLZ*',
					en: 'ZIP code',
					fr: 'Code postal',
					ir: 'Codice postale',
				},
				span: '6'
			},
			{
				id: 'country',
				required: false,
				type: 'text',
				label: {
					de: 'Land',
					en: 'Country',
					fr: 'Pays',
					it: 'Paese',
				},
				span: '6'
			},
			{
				id: 'company',
				required: true,
				type: 'text',
				label: {
					de: 'Unternehmen (Firmenname)*',
					en: 'Company (company name)*',
					fr: 'Société (nom de la société)*',
					it: "Azienda (nome dell'azienda)*",
				},
				span: '6'
			},
			{
				id: 'comment',
				required: true,
				type: 'textarea',
				label: {
					de: 'Kommentar*',
					en: 'Comment*',
					fr: 'Commentaire*',
					it: 'Commento*',
				},
				span: '12'
			},
		]
	}
]
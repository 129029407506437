import React from 'react';
import styled from "styled-components";
import Newsletter from "./Newsletter";
import SocialPayment from "./SocialPayment";
import Contact from "./Contact";
import Terms from "./Terms";
import BottomLine from "./BottomLine";
import Account from "./Account";
import { FooterGrid, Line, StyledFooter } from "./Styles";
import theme from "../styled/Theme";


const StyledLine = styled(Line)`
	
  @media (min-width: ${theme.breakpoints.sm}) {grid-column: 1 / -1}
  @media (min-width: ${theme.breakpoints.md}) {grid-column: 1 / -1}
  @media (min-width: ${theme.breakpoints.xl}) {grid-column: 2 / -1}
`

const TopLine = styled(StyledLine)`
  @media (min-width: ${theme.breakpoints.sm}) {display: block}
  @media (min-width: ${theme.breakpoints.md}) {display: block}
  @media (min-width: ${theme.breakpoints.xl}) {display: none}
	
  @media (min-width: ${theme.breakpoints.sm}) {grid-column: 1 / -1}
  @media (min-width: ${theme.breakpoints.md}) {grid-column: 1 / -1}
  @media (min-width: ${theme.breakpoints.xl}) {grid-column: 2 / -1}
`

const Footer = ({contact, account, terms, newsletter, social}) => {

	return (
			<StyledFooter>

				<FooterGrid>
					<TopLine/>

					<Contact {...contact}/>
					<Newsletter {...newsletter}/>
					<SocialPayment {...social}/>

					<StyledLine/>

					<Account {...account}/>
					<Terms {...terms}/>
				</FooterGrid>
				<BottomLine/>
			</StyledFooter>
);
};

export default Footer;
import React from 'react';
import ProductBox from "../ProductBox/ProductBox";
import styled from "styled-components";
import theme from "../../styled/Theme";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import 'swiper/css/pagination';

const Outter = styled.div`
  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 0 32px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    padding: 0 32px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    padding: 0 1rem
  }`

const Wrapper = styled.div`
  width: 80vw;
  max-width: 1200px;
  margin: auto;



  @media (min-width: ${theme.breakpoints.sm}) {
    padding-top: 52px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    padding-top: 54px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    padding-top: 124px
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    padding-bottom: 88px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    padding-bottom: 110px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    padding-bottom: 66px
  }
  overflow: hidden;

  .swiper {
    overflow: visible
  }
  .swiper-pagination {
    bottom: -47px;
    .swiper-pagination-bullet {
      border: 1px solid white;
      &.swiper-pagination-bullet-active {
        background: white;
      }
    }
  }
  .swiper-wrapper {
    @media (min-width: ${theme.breakpoints.xl}) {
      justify-content: center;
    }
  }

`

const BoxWrapper = styled.div`
  display: flex;
  align-items: center;
	justify-content: center;
`

const ProductList = ({ id, products }) => {
	const activeTabs = useSelector( state => state.activeTabs )
	const dispatch = useDispatch()


	const onClick = (product) => dispatch( { type: 'setActiveTab', elementId: id, product } )


	const slideChange = (e) => {
		console.log(e)
		if (
				e.currentBreakpoint === '0' ||
				e.currentBreakpoint === '610'
		) {
			dispatch( { type: 'setActiveTab', elementId: id, product:  products[e.activeIndex].id } )
		}
	}

	return (
			<Outter>


			<Wrapper>
				<Swiper
						modules={[Pagination]}
						onSlideChange={slideChange}
						pagination={{ clickable: true, type: 'bullets' }}
						breakpoints={{
							// when window width is >= 640px
							0: {
								watchOverflow: true,
								slidesPerView: 1,
								pagination: {bulletElement: 'bullet',clickable : true},

							},

							900: {
								watchOverflow: true,
								slidesPerView: 3,
							},
							1200: {
								watchOverflow: true,
								slidesPerView: 4,
							},
						}}
				>
					{products.map( (product, key) => (
							<SwiperSlide key={key}>
								<BoxWrapper>
									<ProductBox {...product} uppercase={false} onClick={() => onClick( product.id )}/>
								</BoxWrapper>
							</SwiperSlide>
					) )}

				</Swiper>
			</Wrapper>
			</Outter>

	);
};

export default ProductList;